import { SelectOption } from '@chiroup/components';
import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { useEffect, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { useDebounce } from './useDebounce';
import {
  CodeSets,
  BillingCodeReference,
} from '@chiroup/core/types/BillingCode.type';

const query = (endpoint?: string, local?: boolean) => {
  return async (context: QueryFunctionContext) => {
    const clinicId = context.queryKey[1];
    const type = Array.isArray(context.queryKey[2])
      ? context.queryKey[2].join('|')
      : context.queryKey[2];
    const search = context.queryKey[3];
    const disregardSearch = context.queryKey[6];
    if (!search && !disregardSearch) return { count: -1, data: [], page: -1 };

    return ChiroUpAPI.get(
      'api',
      endpoint ? endpoint : `/settings/${clinicId}/codes`,
      {
        queryParams: {
          type,
          search,
          weighted: context.queryKey[4],
          page: context.pageParam,
          local,
        },
      },
    );
  };
};

type UseCodesParamType = {
  clinicId?: number;
  type: CodeSets | CodeSets[];
  weighted?: boolean | undefined;
  endpoint?: string | undefined;
  search: string;
  local?: boolean;
  disregardSearch?: boolean;
};

export const useCodes = (params: UseCodesParamType) => {
  const [restError, setRestError] = useState<any | null>(null);
  const {
    type,
    weighted,
    endpoint,
    local = false,
    disregardSearch = false,
  } = params;
  const search = useDebounce(params.search, 750);
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<{
      data: BillingCodeReference[];
      count: number;
      page: number;
    }>(
      [
        'codes',
        params.clinicId,
        type,
        search,
        weighted,
        local,
        disregardSearch,
      ],
      query(endpoint, local),
      {
        getNextPageParam: (lastGroup) =>
          (lastGroup || {}).page ? (lastGroup || {}).page + 1 : undefined,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 0,
        onError: (e) => {
          setRestError(e);
          console.log({ queryError: e });
        },
      },
    );

  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    let recs: any = [];
    try {
      if (data) {
        recs = data?.pages
          .reduce((acc, curr) => {
            return [...acc, ...curr.data];
          }, [] as BillingCodeReference[])
          .map((code) => ({
            text: `${code.code} - ${code.description}`,
            value: code.code,
            data: {
              description: code.description,
              codeSet: code.codeSet,
            },
          }));
      }
      setOptions(recs);
    } catch (e: any) {
      setRestError(e);
      console.error({ e });
    }
  }, [data]);

  return {
    data,
    options,
    setOptions,
    isFetching,
    fetchNextPage,
    hasNextPage,
    restError,
    setRestError,
    isFetchingNextPage,
  };
};
