import { OpenClosedStates } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { Transition } from '@headlessui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import userService from '../../services/user.service';
import Button, { ButtonColors } from '../common/Button';
import Overlay from '../common/Overlay';
import UserGeneralFormElements from './detail/UserGeneralFormElements';
import { User, UserRoles } from '@chiroup/core/types/User.type';
import { isEmpty } from '@chiroup/core/functions/isEmpty';

const validation = {
  title: {
    required: {
      message: 'Title is required.',
    },
  },
  fname: {
    required: {
      message: 'First name is required.',
    },
  },
  lname: {
    required: {
      message: 'Last name is required.',
    },
  },
  email: {
    required: {
      message: 'Email is required.',
    },
  },
  phone: {
    function: {
      value: (value: Partial<NewUser>) => {
        const { phone } = value;
        if (
          phone?.[1] === '1' &&
          phone?.split(' ')?.[0]?.length === 6 &&
          phone?.length < 13
        ) {
          return 'Phone number must be 7 digits.';
        } else if (phone?.[1] === '1' && phone?.length < 13) {
          return 'Phone number must be 10 digits.';
        }
        return false;
      },
    },
  },
  // role: {
  //   required: {
  //     message: 'User role is required.',
  //   },
  // },
};

type Props = {
  state: OpenClosedStates;
  close: (val?: User) => void;
  onCreate?: () => void;
  // lastName?: string;
  clinicID?: number;
  isEHR?: boolean;
};

export type NewUser = User & {
  clinicID: number;
  role: UserRoles[];
};

const UserCreate: React.FC<Props> = ({
  state,
  close,
  onCreate,
  clinicID,
  // lastName,
  isEHR,
}) => {
  const { value, registerSubmit, isDirty, errors, onChange, patchValue } =
    useForm<NewUser>({ title: 'Dr' }, validation);
  const [saving, setSaving] = useState(false);
  const { createToast } = useContext(ToastContext);

  useEffect(() => {
    if (!isEmpty(clinicID)) {
      patchValue({ clinicID });
    }
  }, [clinicID, patchValue, state]);

  const onSubmit = async (user: Partial<User>) => {
    setSaving(true);
    try {
      const res = await userService.create(user);
      setSaving(false);
      close(res);
      return res;
      // onCreate();
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
    return user;
  };

  const onSuccess = (data: User) => {
    createToast({
      title: 'Successfully created user!',
      description: null,
      type: ToastTypes.Success,
      duration: 5000,
    });
    close();
    onCreate?.();
  };

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      hidden={state === OpenClosedStates.Closed}
    >
      <div className="flex items-end justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <Overlay show={state === OpenClosedStates.Open} />
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
        &#8203;
        <Transition
          show={state === OpenClosedStates.Open}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div
            className="inline-block align-bottom bg-white dark:bg-darkGray-700 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
                <svg
                  className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
                  id="modal-headline"
                >
                  Add new user
                </h3>
                <div className="mt-2 text-left">
                  <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
                    <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                      <UserGeneralFormElements
                        fieldErrors={errors.fieldErrors}
                        value={value}
                        onChange={onChange}
                        patchValue={patchValue}
                        clinicID={clinicID}
                        isEHR={isEHR}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <span className="flex w-full gap-x-4">
                <Button
                  text="Close"
                  onClick={() => close()}
                  fullWidth
                  color={ButtonColors.plain}
                  className="border border-gray-300 dark:border-darkGray-500"
                />
                <Button
                  text="Create"
                  onClick={registerSubmit(onSubmit, {
                    onSuccess,
                  })}
                  fullWidth
                  loading={saving}
                  disabled={!isDirty}
                />
              </span>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default UserCreate;
