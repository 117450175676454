import { OpenClosedStates } from '@chiroup/components';
import { User } from '@chiroup/core/types/User.type';
import { useForm } from '@chiroup/hooks';
import React from 'react';
import { UseMutateFunction } from 'react-query';
import Button, { ButtonColors } from '../../common/Button';
import SlideOver from '../../common/SlideOver';
import UserGeneralFormElements from './UserGeneralFormElements';
import { ErrorResponse } from '@chiroup/core/types/ErrorResponse.type';

type Props = {
  editSlideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  defaultValues: Partial<User>;
  update: UseMutateFunction<
    Partial<User>,
    ErrorResponse,
    Partial<User>,
    unknown
  >;
  isUpdating: boolean;
};

const validation = {
  fname: {
    required: {
      message: 'First name is required.',
    },
  },
  lname: {
    required: {
      message: 'Last name is required.',
    },
  },
  phone: {
    function: {
      value: (value: Partial<User>) => {
        const { phone } = value;
        if (
          phone?.[1] === '1' &&
          phone?.split(' ')?.[0]?.length === 6 &&
          phone?.length < 13
        ) {
          return 'Phone number must be 7 digits.';
        } else if (phone?.[1] === '1' && phone?.length < 13) {
          return 'Phone number must be 10 digits.';
        }
        return false;
      },
    },
  },
};

const UserGeneralEditPanel: React.FC<Props> = ({
  editSlideOverState,
  updateSlideOverState,
  defaultValues,
  update,
  isUpdating,
}) => {
  const { value, registerSubmit, isDirty, errors, onChange, patchValue } =
    useForm<User>(defaultValues, validation);

  const onSubmit = async (patient: Partial<User>) => {
    if (defaultValues.ID) {
      patient.ID = defaultValues.ID;
    }
    update(patient);
    return patient;
  };

  return (
    <SlideOver
      title="Edit user"
      slideOverState={editSlideOverState}
      updateSlideOverState={updateSlideOverState}
      buttons={
        <>
          <Button
            text="Close"
            onClick={() => updateSlideOverState(OpenClosedStates.Closed)}
            color={ButtonColors.plain}
          />
          <Button
            text="Save"
            disabled={!isDirty || isUpdating}
            loading={isUpdating}
            onClick={registerSubmit(onSubmit, {})}
          />
        </>
      }
    >
      <form>
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            <UserGeneralFormElements
              fieldErrors={errors.fieldErrors}
              value={value}
              onChange={onChange}
              patchValue={patchValue}
            />
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default UserGeneralEditPanel;
