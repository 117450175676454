import { formatPhone } from '@chiroup/core/functions/format';
import { useForm } from '@chiroup/hooks';
import { IdentificationIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useStudentVerification from '../../hooks/useStudentVerification';
import Button, { ButtonColors } from '../common/Button';
import Filters from '../common/Filters';
import Modal from '../common/Modal';
import SearchInput from '../common/fields/inputs/SearchInput';
import List from '../common/list/List';
import ListItem from '../common/list/ListItem';
import Header from '../layout/Header';
import filesService from '../../services/files.service';

const StudentVerification = () => {
  const { data, isFetching, accept, reject, isSubmitting } =
    useStudentVerification();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { value, onChange } = useForm<any>({ search: '' });

  const filteredData = data?.filter((row) => {
    if (!value.search) return true;
    const search = value.search.toLowerCase();
    const name = `${row.fname} ${row.lname}`.toLowerCase();
    const email = row.email?.toLowerCase();
    const phone = row.phone;

    return (
      name?.includes(search) ||
      email?.includes(search) ||
      phone?.includes(search)
    );
  });

  const selectId = async (id: string) => {
    const res = await filesService.get(id);
    setSelectedId(res);
  };

  return (
    <>
      <Header title="Student Verification">
        <Filters
          main={
            <SearchInput onChange={onChange('search')} value={value.search} />
          }
        />
      </Header>
      <List isFetching={isFetching} clientSide>
        {filteredData?.map((row, i) => (
          <div key={row.ID}>
            <ListItem index={i}>
              <div className="flex flex-col gap-4">
                <div className="w-full flex flex-row justify-between">
                  <div>
                    <div className="flex flex-row gap-2 items-center">
                      <Link to={`/users/${row.ID}`}>
                        <div className="leading-6 text-primary-500 font-semibold tracking-wide uppercase hover:underline">
                          {row.fname} {row.lname}
                        </div>
                      </Link>
                      {!!row.image && (
                        <IdentificationIcon
                          className="w-4 h-4 cursor-pointer"
                          onClick={() => {
                            selectId(row.image);
                          }}
                        />
                      )}
                    </div>
                    <div>
                      Graduation date:{' '}
                      {dayjs(row.GraduationDate).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="text-right">
                    <div>{formatPhone(row.phone)}</div>
                    <div>{row.email}</div>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <Button
                    color={ButtonColors.plainWithBorder}
                    onClick={() => {
                      accept(row.ID);
                    }}
                    disabled={isSubmitting}
                    text="Accept"
                  />
                  <Button
                    color={ButtonColors.plainWithBorder}
                    onClick={() => {
                      reject(row.ID);
                    }}
                    disabled={isSubmitting}
                    text="Reject"
                  />
                </div>
              </div>
            </ListItem>
          </div>
        ))}
      </List>
      <Modal isOpen={!!selectedId} close={() => setSelectedId(null)}>
        <div className="flex justify-center">
          <img src={selectedId as string} alt="ID" />
        </div>
      </Modal>
    </>
  );
};

export default StudentVerification;
