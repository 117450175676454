import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDevtools from '../../../hooks/useDevtools';
import { Button, Checkbox, Select, Textarea } from '@chiroup/components';
import { PuzzlePieceIcon } from '@heroicons/react/24/outline';
import { InvoicePanelParams } from '@chiroup/core/types/Devtools.type';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { AllInvoiceStatusOptions } from '@chiroup/core/types/Invoice.type';

const defaultValues: InvoicePanelParams = {
  options: { convertIssuesToNotes: true },
};

const InvoicePanel: React.FC = () => {
  const { isFetching, move } = useDevtools('invoice-panel');
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<InvoicePanelParams>({ defaultValues });

  const onSubmit = (data: InvoicePanelParams) => {
    if (move) {
      move(data)
        .then((res) => res)
        .catch((e) => console.error(e));
    } else {
      console.error('move is not defined');
    }
  };

  return (
    <div className="p-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="bg-white border border-gray-400 inset-4 rounded-lg">
          <div className="p-4 flex flex-row justify-between">
            <h1 className="font-extrabold">Force Invoice Status Change</h1>
            <Button
              text="Go!"
              disabled={isFetching}
              loading={isFetching}
              type="submit"
              icon={<PuzzlePieceIcon />}
            />
          </div>
          <div className="p-4 m-4 border border-gray-400 bg-gray-50 rounded-lg text-sm space-y-4">
            <p className="text-red-500">* * * EXTREME DANGER AHEAD * * *</p>
            <p>
              This supports moving one or more invoices to a specific status.
              <em className="text-red-500">There are no restrictions.</em> Any
              target status may be chosen. This will clear any issues and notes
              on the last invoice transition unless the option to copy the
              issues to notes is selected.
            </p>
            <p>
              The current status is optional and may be used as a type of
              filter. That is, if the invoice is not in the selected status, it
              will be ignored.
            </p>
            <p>
              There is NO UI at the current time for the response, so open the
              browser console.
            </p>
            <p>
              It returns the original invoice, so this can be used to reverse
              the process (painfully, but possible) if the HAR is saved after
              the run.
            </p>
          </div>
          <div className="p-4 flex flex-col space-y-4">
            <Controller
              name="finalStatus"
              control={control}
              rules={{ required: 'Required.' }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Final Status"
                  options={AllInvoiceStatusOptions}
                  tooltip="The status to which the invoices will be moved."
                  errors={
                    errors.finalStatus
                      ? ({
                          message: errors.finalStatus.message,
                        } as FormError)
                      : undefined
                  }
                  limit={1}
                />
              )}
            />
            <Controller
              name="currentStatus"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Current Status"
                  options={[
                    { value: undefined, text: '- none -' },
                    ...AllInvoiceStatusOptions,
                  ]}
                  tooltip={[
                    'When set, this represents the status the invoice must be',
                    'in before it can be moved to the final status. When not set,',
                    'the current status of the invoice is ignored.',
                  ].join(' ')}
                  errors={
                    errors.currentStatus
                      ? ({
                          message: errors.currentStatus.message,
                        } as FormError)
                      : undefined
                  }
                  limit={1}
                />
              )}
            />
            <Controller
              name="sInvoices"
              control={control}
              rules={{
                validate: {
                  nums: (val: string | undefined, opts): any => {
                    if (!val) return 'Required.';
                    const nums = val.split('\n').map((v) => v.trim());
                    if (nums.some((v) => isNaN(parseInt(v)))) {
                      return 'Only numbers are allowed.';
                    }
                    return true;
                  },
                },
              }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  label="Invoice IDs"
                  tooltip='One per line. Example: "1234\n5678\n91011"'
                  rows={10}
                  errors={
                    errors.sInvoices
                      ? ({
                          message: errors.sInvoices.message,
                        } as FormError)
                      : undefined
                  }
                />
              )}
            />
            <Controller
              name={`options.convertIssuesToNotes`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  value={field?.value}
                  onChange={field.onChange}
                  label={`Convert issues to notes.`}
                  tooltip="If checked, any issues on the last invoice transition will be copied to notes."
                  className="col-span-2"
                />
              )}
            />
          </div>
        </section>
      </form>
    </div>
  );
};

export default InvoicePanel;
