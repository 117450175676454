import { ButtonColors } from './Button';
import { useContext, useMemo, useState } from 'react';
import businessRulesService from '../../../../apps/chiroup/src/services/businessRules.service';
import {
  NoSymbolIcon as NoSymbolIconOutline,
  BugAntIcon as BugAntIconOutline,
  CheckCircleIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import { TrivialTooltip } from './TrivialTooltip';
import {
  ToastContext,
  ToastTypes,
} from '../../../../apps/chiroup/src/contexts/toast.context';
import { Loading } from './Loading';
import { classNames } from '@chiroup/core/functions/classNames';
import { Link } from 'react-router-dom';
import {
  BusinessRuleItemType,
  BusinessRuleSeverity,
  BusinessRuleSolutionEnum,
} from '@chiroup/core/constants/globals';
import { clog } from '@chiroup/core/functions/clog';

type Props = {
  result: BusinessRuleItemType | null;
  trace?: boolean;
  data?: any;
  location?: any;
  fixAppliedCallback?: (response: any) => void;
  workTextErrors?: boolean;
  resolveId?: number;
  issueResolved?: boolean;
  resolveCallback?: ({
    resolveId,
    location,
  }: {
    resolveId: number | null;
    location: any;
  }) => void;
  hideResolved?: boolean;
  readonly?: boolean;
  isRestActive?: boolean;
  isaNote?: boolean;
};

const linkBlurb =
  'The link to the left branches to the location to fix this issue.';

const color: Record<BusinessRuleSeverity, string> = {
  [BusinessRuleSeverity.Error]: 'text-red-500',
  [BusinessRuleSeverity.Warning]: 'text-yellow-500',
  [BusinessRuleSeverity.Info]: 'text-blue-500',
};

export const BusinessRuleSolution: React.FC<Props> = ({
  result = null,
  trace = false,
  data = null,
  fixAppliedCallback = (response: any) => {
    console.log(response, 'fixAppliedCallback');
  },
  location = null,
  workTextErrors = false,
  resolveId = null,
  issueResolved = false,
  resolveCallback = ({
    resolveId,
    location,
  }: {
    resolveId: number | null;
    location: any;
  }) => {
    console.log({ resolveCallback: { resolveId, location } });
  },
  hideResolved = false,
  readonly = false,
  isRestActive = false,
  isaNote = false,
}) => {
  const { createToast } = useContext(ToastContext);
  const [isFixing, setIsFixing] = useState(false);
  const displayText = useMemo(() => {
    let s =
      typeof result === 'string'
        ? result
        : result?.text ?? '-- unknown error --';
    // The danger here are long lines without white space. Like, if we put JSON in a message.
    if (s.length > 72) {
      s = s.replace(/,[^\s]/g, ', ').replace(/":"/g, '" : "');
    }
    return s;
  }, [result]);

  const severity = useMemo(() => {
    if (result?.severity) return result.severity;
    return isaNote ? BusinessRuleSeverity.Info : BusinessRuleSeverity.Error;
  }, [isaNote, result?.severity]);

  const possibleSolutionTip = useMemo(() => {
    if (result?.tip) return result.tip;
    if (result?.path) {
      return (
        `The path to fix this issue is: ${result.path.join(' => ')}.` +
        (result?.url ? ' ' + linkBlurb : '')
      );
    }
    if (result?.url) {
      return linkBlurb;
    }
    return undefined;
  }, [result]);

  if (!result) {
    if (trace) clog(`BusinessRuleSolution: No result.`);
    return null;
  }

  if (hideResolved && issueResolved) {
    if (trace) clog(`BusinessRuleSolution: Hide Resolved.`);
    return null;
  }

  if (readonly) {
    if (trace) clog(`BusinessRuleSolution: Readonly.`);
    return (
      <div className={classNames('flex flex-row', color[severity])}>
        <div>{displayText}</div>
      </div>
    );
  }

  switch (result?.solution) {
    case BusinessRuleSolutionEnum.PrimaryInvoiceWaiting:
    case BusinessRuleSolutionEnum.LockTransactionServices: {
      return (
        <div
          className={classNames(
            'flex flex-row w-full justify-between',
            color[severity],
          )}
        >
          <div>{displayText}</div>
          <div className="cursor-pointer flex flex-row space-x-1 justify-end w-grow align-top">
            <button
              disabled={isFixing}
              color={ButtonColors.primary}
              onClick={async () => {
                if (isFixing) return;
                if (isRestActive) return;
                setIsFixing(() => true);
                try {
                  const res = (await businessRulesService.solve({
                    clinicId: data.clinicId,
                    data,
                    // trace: true,
                    result,
                  })) as unknown as any;
                  createToast({
                    title: `Fix Success`,
                    description: `${res.solutionMessage ?? res.message}`,
                    type: ToastTypes.Success,
                    duration: 5000,
                  });
                  // TODO: Really? Two callbacks? Maybe this can be one.
                  if (fixAppliedCallback) {
                    fixAppliedCallback({ response: res, result, location });
                  }

                  if (resolveCallback) resolveCallback({ resolveId, location });
                } catch (e: any) {
                  console.error(e);
                  let text = 'Unknown error.';
                  if (e?.response?.data?.message) {
                    text = e.response.data.message;
                  }

                  createToast({
                    title: `Fix Failed`,
                    description: text,
                    type: ToastTypes.Fail,
                    duration: 5000,
                  });
                }
                setIsFixing(() => false);
              }}
            >
              <div className="">
                <div className="relative h-6 w-6 text-primary-500">
                  <NoSymbolIconOutline className="absolute top-0 left-0" />
                  <BugAntIconOutline className="absolute top-0 left-0 scale-50" />
                </div>
              </div>
            </button>
            {result.url ? (
              <div className="cursor-pointer text-primary-500">
                <Link to={result.url} target="_blank">
                  <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                </Link>
              </div>
            ) : null}{' '}
            <div className="w-4 relative">
              <TrivialTooltip
                text={possibleSolutionTip}
                containerClassName="absolute top-0 -left-1"
              />
            </div>
            <div className="">
              <Loading style={`tiny-inline-gray`} flag={isFixing} />
            </div>
            <div
              className="flex cursor-pointer text-gray-300 hover:text-primary-500"
              onClick={() => {
                if (isFixing) return;
                if (isRestActive) return;
                if (resolveCallback) resolveCallback({ resolveId, location });
              }}
            >
              {issueResolved ? (
                'blah'
              ) : (
                <CheckCircleIcon className={classNames('h-6 h4')} />
              )}
            </div>
          </div>
        </div>
      );
    }
    default: {
      return (
        <div className={color[severity]}>
          {workTextErrors ? (
            <div className="flex flex-row">
              <div className="flex grow pr-2">
                <div className="flex flex-grow  border-green-500">
                  {displayText}
                </div>

                <div className="flex justify-end  border-green-500">
                  {result.url ? (
                    <div className="cursor-pointer text-primary-500">
                      {' '}
                      <Link to={result.url} target="_blank">
                        <ArrowTopRightOnSquareIcon className="h-6 w-6 ml-1" />
                      </Link>
                    </div>
                  ) : null}{' '}
                </div>
                <div className="flex justify-end  border-green-500">
                  {possibleSolutionTip ? (
                    <TrivialTooltip text={possibleSolutionTip} />
                  ) : null}
                </div>
              </div>
              {severity === BusinessRuleSeverity.Error ? (
                <div
                  className="flex cursor-pointer text-gray-300 hover:text-primary-500"
                  onClick={() => {
                    if (isFixing) return;
                    if (isRestActive) return;
                    if (resolveCallback) {
                      resolveCallback({ resolveId, location });
                    }
                  }}
                >
                  {issueResolved ? (
                    'blah'
                  ) : (
                    <CheckCircleIcon className={classNames('h-6 h4')} />
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <div>{displayText}</div>
          )}
        </div>
      );
    }
  }
};
