import { InfoItemHtml, OpenClosedStates } from '@chiroup/components';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Button, { ButtonColors } from '../../../common/Button';
import InfoItem from '../../../common/info/InfoItem';
import SectionContainer from '../../../layout/SectionContainer';
import SectionHeader from '../../../layout/SectionHeader';
import MarketingDeleteModal from './MarketingDeleteModal';
import MarketingGeneralEditPanel from './MarketingGeneralEditPanel';
import { MarketingCampaignDetail } from '@chiroup/core/types/MarketingCampaign.type';
import { ErrorResponse } from '@chiroup/core/types/ErrorResponse.type';

type Props = {
  marketing: Partial<MarketingCampaignDetail>;
  save: UseMutateFunction<
    Partial<MarketingCampaignDetail>,
    ErrorResponse,
    Partial<MarketingCampaignDetail>,
    unknown
  >;
  del: () => Promise<void>;
};

const MarketingGeneral: React.FC<Props> = ({ marketing, save, del }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteModalState, setDeleteModalState] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [editSlideOverState, setEditSlideOverState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const editOpen = queryParams.open?.includes('edit');
    setEditSlideOverState(
      editOpen ? OpenClosedStates.Open : OpenClosedStates.Closed,
    );
  }, [location.search]);

  const updateSlideOverState = (val: OpenClosedStates) => {
    navigate(`/practice-resources/marketing-campaigns/${marketing.ID}`);
  };

  return (
    <>
      <SectionContainer className="sm:shadow border border-gray-300 dark:border-darkGray-800">
        <SectionHeader
          title="General"
          subtitle="General information about the marketing."
          rightSide={
            <div className="flex flex-row items-center space-x-2.5">
              <Link to="?open=edit" className="hidden sm:block">
                <Button
                  text="Edit"
                  icon={
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  }
                  color={ButtonColors.plainWithBorder}
                />
              </Link>
            </div>
          }
        />
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <InfoItem label="Type" value={marketing.type} />
            <InfoItem label="URL" value={marketing.url} />
            <InfoItem
              label="Description"
              value={marketing.descr}
              className="sm:col-span-2"
            />
            <InfoItemHtml
              className="sm:col-span-2"
              label="Implementation protocol"
              value={marketing.ImplementationProtocol || ''}
            />
          </dl>
        </div>
      </SectionContainer>
      <MarketingGeneralEditPanel
        editSlideOverState={editSlideOverState}
        updateSlideOverState={updateSlideOverState}
        defaultValues={marketing}
        save={save}
      />
      {marketing.ID && (
        <MarketingDeleteModal
          state={deleteModalState}
          setState={setDeleteModalState}
          del={del}
        />
      )}
    </>
  );
};

export default MarketingGeneral;
