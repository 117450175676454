import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { ToastContext, ToastTypes } from '../../../../contexts/toast.context';
import Button, { ButtonColors } from '../../../common/Button';
import Header from '../../../layout/Header';
import ClinicSettingsLocation from './ClinicLocation';
import ClinicSettingsLocationDetail from './ClinicLocationDetail';
import { ClinicLocation, ListClinic } from '@chiroup/core/types/Clinic.type';

type Props = {
  clinicId?: number;
  locations?: ClinicLocation[];
  setClinic?: Dispatch<SetStateAction<Partial<ListClinic> | undefined>>;
};

const ClinicLocations: React.FC<Props> = ({
  clinicId,
  locations = [],
  setClinic,
}) => {
  const { createToast } = useContext(ToastContext);
  const [selectedLocation, setSelectedLocation] =
    useState<Partial<ClinicLocation> | null>(null);
  const [removingLocation, setRemovingLocation] = useState(false);

  const onSuccess = () => {
    setSelectedLocation(null);
    createToast({
      title: 'Success',
      description: <>Successfully saved location.</>,
      type: ToastTypes.Success,
      duration: 5000,
    });
  };

  const saveLocation = async (val: Partial<ClinicLocation>) => {
    const res = (await ChiroUpAPI.put(
      'api',
      val.ID
        ? `/clinics/${clinicId}/locations/${val.ID}`
        : `/clinics/${clinicId}/locations`,
      {
        body: val,
      },
    )) as ClinicLocation;

    setClinic?.((prev) => {
      if (val?.ID) {
        return {
          ...prev,
          locations: (prev?.locations || []).map((l) => {
            if (l.ID === res.ID) {
              return res;
            } else if (l.primary && res.primary) {
              l.primary = false;
            }
            return l;
          }),
        };
      } else {
        return {
          ...prev,
          locations: [...(prev?.locations || []), res],
        };
      }
    });

    return res;
  };

  const removeLocation = async (id?: number) => {
    if (!id) return;
    setRemovingLocation(true);
    await ChiroUpAPI.del('api', `/clinics/${clinicId}/locations/${id}`, {});
    setRemovingLocation(false);
  };

  return (
    <>
      <Header
        title="Locations"
        rightSide={
          <Button
            text="Add"
            color={ButtonColors.plainWithBorder}
            className="cursor-pointer"
            icon={
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            }
            onClick={() =>
              setSelectedLocation({
                country: 'USA',
              })
            }
            // loading={isSubmitting}
          />
        }
      />
      {/* <ClinicSettingsTabs /> */}
      <div className="p-6">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-300">
            {locations?.map((location, i) => (
              <li
                key={location.ID}
                onClick={() => setSelectedLocation(location)}
              >
                <ClinicSettingsLocation location={location} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ClinicSettingsLocationDetail
        location={selectedLocation}
        close={() => setSelectedLocation(null)}
        onSubmit={saveLocation}
        onSuccess={onSuccess}
        removeLocation={() => removeLocation(selectedLocation?.ID)}
        removingLocation={removingLocation}
      />
    </>
  );
};

export default ClinicLocations;
