import React, { ChangeEvent } from 'react';
import { v4 } from 'uuid';
import { FieldErrors } from './FieldErrors';
import { TrivialTooltip } from '../TrivialTooltip';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { classNames } from '@chiroup/core/functions/classNames';

type Props = {
  className?: string;
  errors?: FormError;
  label?: string | React.ReactNode;
  name?: string;
  onChange?: (val: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  description?: string;
  showRingAroundInput?: boolean;
  tooltip?: string;
  tooltipClassName?: string;
  checkboxRef?: React.Ref<HTMLInputElement>;
  hidden?: boolean;
};

export const Checkbox: React.FC<Props> = ({
  className = '',
  errors,
  label,
  name,
  onChange,
  value,
  disabled = false,
  description,
  showRingAroundInput = false,
  tooltip,
  tooltipClassName,
  checkboxRef = null,
  hidden = false,
}) => {
  const uuid = v4();

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <div
      className={classNames(
        'flex items-start',
        className,
        hidden ? 'hidden' : '',
      )}
    >
      <div className="flex flex-col items-start">
        <div className="flex items-center">
          <input
            id={uuid}
            name={name}
            type="checkbox"
            className={classNames(
              'h-4 w-4 rounded',
              showRingAroundInput
                ? 'ring-2 ring-offset-2 ring-primary-600'
                : '',
              disabled
                ? 'text-gray-400 border-gray-400'
                : 'text-primary-600 focus:ring-primary-600',
              value ? 'border-primary-600' : 'border-gray-300',
            )}
            checked={value}
            onChange={onChangeValue}
            disabled={disabled}
            ref={checkboxRef}
          />
          <label
            htmlFor={uuid}
            className={classNames(
              'ml-2 block text-sm leading-5',
              errors
                ? 'text-red-500'
                : disabled
                  ? 'text-gray-400 dark:text-darkGray-100'
                  : 'text-gray-900 dark:text-darkGray-200',
            )}
          >
            {label}
            {tooltip ? (
              <TrivialTooltip text={tooltip} tipClassName={tooltipClassName} />
            ) : null}
          </label>
        </div>
        {!!description && (
          <div className="block text-sm leading-5 text-gray-500 ml-6 mt-1">
            {description}
          </div>
        )}
      </div>
      <div className="ml-2">
        <FieldErrors errors={errors} />
      </div>
    </div>
  );
};
