import { LoadingPage, Toggle } from '@chiroup/components';
import Header from '../layout/Header';
import useFlags from './hooks/useFlags';
import { flagGroupsArr } from '@chiroup/core/constants/flags';

const Flags = () => {
  const { data, isFetching, updateFlag } = useFlags();

  return (
    <>
      <Header title="Feature flags" />
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div className="m-6">
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Flag
                        </th>
                        {flagGroupsArr.map((flagGroup) => (
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {flagGroup.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300 bg-white">
                      {data?.map((flag) => (
                        <tr key={flag.flag}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {flag.name}
                          </td>
                          {flagGroupsArr.map((flagGroup) => (
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Toggle
                                value={flag.flagGroups?.includes(
                                  flagGroup.value,
                                )}
                                onChange={(val) => {
                                  updateFlag(flag.flag, flagGroup.value, val);
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Flags;
