/**
 * Got tired of a try/catch around this feature everywhere.
 *
 * It has to look like a JSON string or no attempt is made to parse it on the
 * theory it is better to NOT throw an error if it can be prevented.
 *
 * @param jsonString
 * @returns
 */

import { STRING_BOOLEAN_HASH } from '../constants/globals';

// Written by CoPilot.
export function compare({
  obj1,
  obj2,
  options = {
    ignoreLeafKey: {},
    compareLeafAs: {},
    strict: false,
    traceKey: {},
  },
}: {
  obj1: { name?: string; value: any };
  obj2: { name?: string; value: any };
  options?: {
    ignoreLeafKey?: STRING_BOOLEAN_HASH;
    compareLeafAs?: { [key: string]: 'string' | 'number' | 'boolean' };
    strict?: boolean;
    traceKey?: STRING_BOOLEAN_HASH;
  };
}): any {
  const diffs: any = {},
    obj1Name = obj1.name ?? 'obj1',
    obj2Name = obj2.name ?? 'obj2',
    strict = options?.strict;

  function findDiffs(o1: any, o2: any, path: string = '') {
    if (!o1 || !o2) return { error: 'missing object' };
    for (const key in o1) {
      const o1AsAny = o1 as any,
        value = o1AsAny[key] ?? null;
      if (options?.traceKey?.[key]) {
        console.log({ loop: 'key in o1', key, path });
      }
      if (!(key in o2)) {
        if (options.traceKey?.[key]) {
          console.log({
            loop: 'key in o1; key NOT in o2',
            strict,
            key,
            path,
            typeofObj: typeof o1AsAny,
            value,
            typeofValue: typeof value,
            test: !strict && value === null,
          });
        }

        if (options?.ignoreLeafKey?.[key]) continue;
        if (!strict && (o1AsAny[key] === null || o1AsAny[key] === undefined)) {
          continue;
        }
        diffs[path + key] = {
          type: `missing_in_${obj2Name}`,
          value: o1[key],
        };
      } else if (typeof o1[key] === 'object' && typeof o2[key] === 'object') {
        findDiffs(o1[key], o2[key], path + key + '.');
      } else if (o1[key] !== o2[key]) {
        if (options?.ignoreLeafKey?.[key]) continue;
        if (options?.compareLeafAs?.[key] === 'string') {
          if (String(o1[key]) === String(o2[key])) {
            continue;
          }
        } else if (options?.compareLeafAs?.[key] === 'number') {
          if (Number(o1[key]) === Number(o2[key])) {
            continue;
          }
        } else if (options?.compareLeafAs?.[key] === 'boolean') {
          if (Boolean(o1[key]) === Boolean(o2[key])) {
            continue;
          }
        }

        diffs[path + key] = {
          type: 'value_changed',
          value1: o1[key],
          value2: o2[key],
        };
      }
    }

    for (const key in o2) {
      const o2AsAny = o2 as any,
        value = o2AsAny[key] ?? null;

      if (options?.traceKey?.[key]) {
        console.log({ loop: 'key in o2', key, path, value });
      }
      if (!(key in o1)) {
        if (options?.ignoreLeafKey?.[key]) continue;
        if (options.traceKey?.[key]) {
          console.log({
            loop: 'key in o2; key NOT in o1',
            strict,
            key,
            path,
            // obj: o2AsAny,
            typeofObj: typeof o2AsAny,
            value,
            typeofValue: typeof value,
            test: !strict && value === null,
          });
        }

        if (!strict && value === null) continue;
        diffs[path + key] = {
          type: `missing_in_${obj1Name}`,
          value: o2[key],
        };
      } else {
        if (options?.traceKey?.[key]) {
          console.log({
            loop: 'key in o2; key in o1',
            key,
            path,
          });
        }
      }
    }
  }

  findDiffs(obj1.value, obj2.value);
  return diffs;
}

const parse = (jsonString: string | null | undefined, def?: any) => {
  let resp = def || null;
  if (!jsonString) {
    return resp;
  }
  if (typeof jsonString === 'object') {
    return jsonString;
  }
  if (typeof jsonString !== 'string') {
    return resp;
  }
  if (jsonString.indexOf('{') !== 0 && jsonString.indexOf('[') !== 0) {
    return resp;
  }

  try {
    resp = JSON.parse(jsonString);
  } catch (e) {
    console.error('Error parsing JSON string:', jsonString);
  }
  return resp;
};

const stringify = (obj: any, def?: string | null) => {
  let resp = def || '';
  if (obj) {
    try {
      resp = JSON.stringify(obj);
    } catch (e) {
      console.error('Error stringifying JSON object:', obj);
    }
  }
  return resp;
};

const pretty = (obj: any, def?: string | null) => {
  let resp = def || '';
  if (obj) {
    try {
      resp = JSON.stringify(obj, null, 2);
    } catch (e) {
      console.error('Error stringifying JSON object:', obj);
    }
  }
  return resp;
};

/**
 * Just seemed we needed this.
 *
 * @param obj
 * @returns
 */
const clone = (obj: any, def?: any) => {
  if (!obj) return def ? def : obj;
  if (typeof obj !== 'object') return def ? def : obj;
  let resp = def || null;
  try {
    resp = JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error('Error cloning JSON object:', obj);
  }
  return resp;
};

const noNulls = (incoming: any) => {
  if (!incoming) return;
  const obj = ChiroUpJSON.clone(incoming);
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      obj[key] = noNulls(obj[key]);
    }
  });
  return obj;
};

/**
 * Just in case we need more functions on JSON objects that we use all the
 * time.
 */
export const ChiroUpJSON = {
  clone,
  compare,
  noNulls,
  parse,
  pretty,
  stringify,
};
