import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import useClinic from '../../../hooks/useClinic';
import Header from '../../layout/Header';
import ClinicGeneral from './ClinicGeneral';
import ClinicTabs from './ClinicTabs';
import ClinicUsers from './ClinicUsers';
import Billing from './billing/Billing';
import ClinicLocations from './locations/ClinicLocations';
import { ListClinic } from '@chiroup/core/types/Clinic.type';

const ClinicContainer = () => {
  const [clinic, setClinic] = useState<Partial<ListClinic>>();
  const [clinicUsers, setClinicUsers] = useState<any>();
  const { clinicID } = useParams();
  const { data, update, refetch, isUpdating, del } = useClinic(clinicID);
  const { pathname } = useLocation();

  useEffect(() => {
    if (data) {
      const { users: clinicUsers, ...theClinic } = data;
      setClinic(theClinic);
      setClinicUsers(clinicUsers);
    }
  }, [data]);

  return clinic ? (
    <>
      <Header title={clinic.name} />
      <ClinicTabs active={pathname} id={clinicID} />
      <Routes>
        <Route
          path="/"
          element={
            <ClinicGeneral
              clinic={clinic}
              update={update}
              isUpdating={isUpdating}
              del={del}
            />
          }
        />
        <Route
          path={`/users`}
          element={
            <ClinicUsers
              users={clinicUsers}
              clinicID={clinic.ID}
              refetch={refetch}
              clinic={clinic}
            />
          }
        />
        <Route
          path={`/locations`}
          element={
            <ClinicLocations
              locations={clinic.locations}
              clinicId={clinic.ID}
              setClinic={setClinic}
            />
          }
        />
        <Route path={`/billing`} element={<Billing clinic={clinic} />} />
      </Routes>
    </>
  ) : null;
};

export default ClinicContainer;
