import { OpenClosedStates } from '@chiroup/components';
import { ErrorResponse } from '@chiroup/core/types/ErrorResponse.type';
import { MarketingCampaignDetail } from '@chiroup/core/types/MarketingCampaign.type';
import { useForm } from '@chiroup/hooks';
import React from 'react';
import { UseMutateFunction } from 'react-query';
import Button, { ButtonColors } from '../../../common/Button';
import SlideOver from '../../../common/SlideOver';
import MarketingGeneralFormElements from './MarketingGeneralFormElements';

const validation = {
  title: {
    required: {
      message: 'Title is required.',
    },
  },
};

type Props = {
  editSlideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  defaultValues: Partial<MarketingCampaignDetail>;
  save: UseMutateFunction<
    Partial<MarketingCampaignDetail>,
    ErrorResponse,
    Partial<MarketingCampaignDetail>,
    unknown
  >;
};

const MarketingGeneralEditPanel: React.FC<Props> = ({
  editSlideOverState,
  updateSlideOverState,
  defaultValues,
  save,
}) => {
  const { value, registerSubmit, isDirty, errors, onChange } =
    useForm<MarketingCampaignDetail>(defaultValues, validation);

  const onSubmit = async (
    marketingCampaign: Partial<MarketingCampaignDetail>,
  ) => {
    if (defaultValues.ID) {
      marketingCampaign.ID = defaultValues.ID;
    }
    save(marketingCampaign);
    return marketingCampaign;
  };

  return (
    <SlideOver
      title="Edit marketing campaign"
      slideOverState={editSlideOverState}
      updateSlideOverState={updateSlideOverState}
      buttons={
        <>
          <Button
            text="Close"
            onClick={() => updateSlideOverState(OpenClosedStates.Closed)}
            color={ButtonColors.plain}
          />
          <Button
            text="Save"
            disabled={!isDirty}
            onClick={registerSubmit(onSubmit, {})}
          />
        </>
      }
    >
      <form>
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            <MarketingGeneralFormElements
              fieldErrors={errors.fieldErrors}
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default MarketingGeneralEditPanel;
