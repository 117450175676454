import { ChiroUpJSON } from '../functions/ChiroUpJSON';
import { IntegrationInvoice, InvoiceStatusEnum } from './Invoice.type';

export type TransactionDebugOptionsInclude = {
  patientTransaction?: boolean;
  patientTransactionItem?: boolean;
  patientTransactionItemInsurance?: boolean;
  patientTransactionItemDiagnosis?: boolean;
  appointment?: boolean;
  encounter?: boolean;
  patient?: boolean;
  invoice?: boolean;
  era?: boolean;
  claimResponseEra?: boolean;
  payorLineItem?: boolean;
  patientInsurance?: boolean;
  appointmentInsurance?: boolean;
  payor?: boolean;
  billingCode?: boolean;
  billingCodePayor?: boolean;
  billingProfile?: boolean;
  billingProfileUser?: boolean;
  provider?: boolean;
  integration?: boolean;
};

export type TransactionDebugOptions = {
  include: TransactionDebugOptionsInclude;
};

export type TransactionDebugParams = {
  fn?: string;
  billingKey?: string;
  invoiceId?: string;
  invoiceNumber?: string;
  eraId?: string;
  options?: TransactionDebugOptions;
};

export type TransactionDebugAnalysisType = {
  message: string;
};

export type TransactionDebugTableType = {
  data: any;
  analysis: TransactionDebugAnalysisType[];
};

export type TransactionDebugResponse = {
  patientTransaction?: TransactionDebugTableType;
  patientTransactionItem?: TransactionDebugTableType;
  patientTransactionItemInsurance?: TransactionDebugTableType;
  patientTransactionItemDiagnosis?: TransactionDebugTableType;
  appointment?: TransactionDebugTableType;
  encounter?: TransactionDebugTableType;
  patient?: TransactionDebugTableType;
  invoice?: TransactionDebugTableType;
  era?: TransactionDebugTableType;
  claimResponseEra?: TransactionDebugTableType;
  payorLineItem?: TransactionDebugTableType;
  patientInsurance?: TransactionDebugTableType;
  appointmentInsurance?: TransactionDebugTableType;
  payor?: TransactionDebugTableType;
  billingCode?: TransactionDebugTableType;
  billingCodePayor?: TransactionDebugTableType;
  billingProvider?: TransactionDebugTableType;
  billingProviderUser?: TransactionDebugTableType;
  provider?: TransactionDebugTableType;
  integration?: TransactionDebugTableType;
};

export type InvoicePanelParams = {
  fn?: string;
  options: {
    convertIssuesToNotes: boolean;
  };
  sInvoices?: string;
  invoices?: string[];
  finalStatus?: InvoiceStatusEnum;
  currentStatus?: InvoiceStatusEnum;
};

export type InvoicePanelResponseItem = {
  invoice: IntegrationInvoice | null | undefined;
  success: boolean;
  messages: string[];
};

export type InvoicePanelResponse = {
  items: InvoicePanelResponseItem[];
};

/**
 * This has the names of the sources and the order IN WHICH THEY SHOULD
 * BE VISITED. This is important to the service as later data sources
 * depend on earlier. However, for humans, we probably want to sort them.
 * by the label or the key.
 */
export const TransactionDebugSources = [
  {
    key: 'patientTransaction',
    checked: true,
    label: 'Patient Transaction',
  },
  {
    key: 'patientTransactionItem',
    checked: true,
    label: 'Patient Transaction Item',
  },
  {
    key: 'patientTransactionItemDiagnosis',
    checked: true,
    label: 'Patient Transaction Item Diagnosis',
  },
  {
    key: 'patientTransactionItemInsurance',
    checked: true,
    label: 'Patient Transaction Item Insurance',
  },
  {
    key: 'appointment',
    checked: true,
    label: 'Appointment',
  },
  {
    key: 'encounter',
    checked: true,
    label: 'Encounter',
  },
  {
    key: 'patient',
    checked: true,
    label: 'Patient',
  },
  {
    key: 'invoice',
    checked: true,
    label: 'Invoice',
  },
  {
    key: 'era',
    checked: true,
    label: 'ERA',
  },
  {
    key: 'claimResponseEra',
    checked: true,
    label: 'Claim Response ERA',
  },
  {
    key: 'payorLineItem',
    checked: true,
    label: 'Payor Line Item',
  },
  {
    key: 'patientInsurance',
    checked: true,
    label: 'Patient Insurance',
  },
  {
    key: 'appointmentInsurance',
    checked: true,
    label: 'Appointment Insurance',
  },
  {
    key: 'payor',
    checked: false,
    label: 'Payor',
  },
  {
    key: 'billingCode',
    checked: false,
    label: 'Billing Code',
  },
  {
    key: 'billingCodePayor',
    checked: false,
    label: 'Billing Code Payor',
  },
  {
    key: 'billingProfile',
    checked: false,
    label: 'Billing Profile',
  },
  {
    key: 'billingProfileUser',
    checked: false,
    label: 'Billing Profile User',
  },
  {
    key: 'provider',
    checked: false,
    label: 'Provider',
  },
  {
    key: 'integration',
    checked: false,
    label: 'Integration',
  },
];

export const TransactionDebugSourcesMap = TransactionDebugSources.reduce(
  (acc, source) => {
    acc[source.key] = source;
    return acc;
  },
  {} as Record<string, (typeof TransactionDebugSources)[0]>,
);

const sortedTransactionDebugSources = ChiroUpJSON.clone(
  TransactionDebugSources,
).sort((a: any, b: any) => a.label.localeCompare(b.label));
const columnSize = Math.ceil(sortedTransactionDebugSources.length / 4);
export const TransactionDebugSourceColumns = Array.from({ length: 4 }, (_, i) =>
  sortedTransactionDebugSources.slice(i * columnSize, (i + 1) * columnSize),
);
