import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';

const studentService = () => {
  const list = async () => {
    return await ChiroUpAPI.get('api', `/students`, {});
  };

  const update = async (id: string, action: 'accept' | 'reject') => {
    const res = await ChiroUpAPI.put('api', `/students/${id}/${action}`, {});
    return res || {};
  };

  return { list, update };
};

export default studentService();
