import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { Input } from './Input';

type Props = {
  name: string;
  className?: string;
  icon?: React.ReactNode;
  label?: string;
  labelClassName?: string;
  value?: string | number | null;
  onChange: (val: any) => void;
  onBlur?: (val: string) => void;
  errors?: FormError;
  disabled?: boolean;
  onFocus?: () => void;
  type?: string;
  hint?: string | React.ReactNode;
  hintOnClick?: () => void;
  autoComplete?: string;
  inputClassName?: string;
  placeholder?: string;
  clickIcon?: boolean;
  autoFocus?: boolean;
  pattern?: string;
  loading?: boolean;
  inputStyle?: { [key: string]: string | number };
  maxLength?: number;
  iconRight?: boolean;
  onEnter?: () => void;
  downError?: string;
  initialValue?: number;
  tooltip?: string;
  tooltipClassName?: string;
  tooltipId?: string;
  inputTitle?: string;
  labelTitle?: string;
  country: string;
};
export const ZipCodeInput: React.FC<Props> = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  errors,
  className = '',
  icon,
  disabled = false,
  onFocus,
  type = 'text',
  hint,
  hintOnClick,
  autoComplete,
  inputClassName,
  inputStyle = {},
  placeholder,
  clickIcon,
  autoFocus = false,
  pattern,
  loading,
  maxLength,
  iconRight,
  labelClassName = 'block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2',
  downError,
  initialValue,
  tooltip,
  tooltipId,
  tooltipClassName,
  inputTitle,
  labelTitle,
  country = 'USA',
}) => {
  const handleInputChange = (e: string) => {
    let inputValue = e;
    if (country === 'USA' || country === 'US') {
      inputValue = inputValue.replace(/[^0-9]/g, '');

      // Check if the length exceeds 9 (5 digits for ZIP + 4 digits for the extra part)
      if (inputValue.length > 9) {
        inputValue = inputValue.slice(0, 9);
      }

      if (inputValue.length > 5) {
        inputValue = `${inputValue.slice(0, 5)}-${inputValue.slice(5)}`;
      }
    }
    onChange(inputValue);
  };

  return (
    <Input
      name={name}
      value={value}
      onChange={(e) => handleInputChange(e)}
      onBlur={onBlur}
      errors={errors}
      className={className}
      icon={icon}
      disabled={disabled}
      onFocus={onFocus}
      type={type}
      hint={hint}
      hintOnClick={hintOnClick}
      autoComplete={autoComplete}
      inputClassName={inputClassName}
      inputStyle={inputStyle}
      placeholder={placeholder}
      clickIcon={clickIcon}
      autoFocus={autoFocus}
      pattern={pattern}
      loading={loading}
      maxLength={maxLength}
      iconRight={iconRight}
      label={label}
      labelClassName={labelClassName}
      downError={downError}
      initialValue={initialValue}
      tooltip={tooltip}
      tooltipId={tooltipId}
      tooltipClassName={tooltipClassName}
      inputTitle={inputTitle}
      labelTitle={labelTitle}
    />
  );
};
