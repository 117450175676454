import { InputMasked, Select } from '@chiroup/components';
import { SCHOOLS, SORTED_SCHOOLS } from '@chiroup/core/constants/schools';
import { useForm } from '@chiroup/hooks';
import { useContext } from 'react';
import Modal from '../../common/Modal';
import Uploader from '../../common/uploader/Uploader';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import userService from '../../../services/user.service';
import Button, { ButtonColors } from '../../common/Button';
import { User } from '@chiroup/core/types/User.type';

type ConvertToEdForm = {
  schoolName?: string;
  GraduationDate?: string;
  status?: string;
  idImage: string | null;
  convertToEd: boolean;
  phone?: string;
  school?: any;
};

type Props = {
  user?: Partial<User>;
  isOpen?: boolean;
  close: () => void;
  refetch: () => void;
};

const UserConvertModal = ({ user, isOpen = false, close, refetch }: Props) => {
  const { value, registerSubmit, onChange, errors, isSubmitting } =
    useForm<ConvertToEdForm>({
      schoolName: user?.schoolName,
      GraduationDate: user?.GraduationDate,
      status: '',
      convertToEd: !user?.schoolName,
      phone: user?.phone,
    });

  const { createToast } = useContext(ToastContext);

  const onSubmit = async (val: Partial<ConvertToEdForm>) => {
    if (!val.convertToEd) {
      createToast({
        title: 'Account is already of type education!',
        description: null,
        type: ToastTypes.Fail,
        duration: 5000,
      });
      return;
    }
    val.school = SCHOOLS.find((school) => school.name === val.schoolName);
    return await userService.convertToEd(user?.ID, val);
  };

  const onSuccess = () => {
    createToast({
      title: 'Successfully converted account to education!',
      description: null,
      type: ToastTypes.Success,
      duration: 5000,
    });
    refetch();
    close();
  };

  const onFail = () => {
    createToast({
      title: 'Failed to convert account to education!',
      description: null,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      className="inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
    >
      <h3
        className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100 flex gap-2"
        id="modal-headline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
          />
        </svg>
        Convert user to education account
      </h3>
      <form>
        <div className="p-6 grid grid-cols-4 gap-2">
          <Select
            name="schoolName"
            className="col-span-2"
            value={value?.schoolName}
            options={SORTED_SCHOOLS}
            onChange={onChange('schoolName')}
            errors={errors?.fieldErrors?.schoolName}
            label="School name *"
            limit={1}
            valueField="name"
            labelField="name"
          />
          <InputMasked
            name="GraduationDate"
            className="col-span-2"
            label="Graduation date *"
            value={value.GraduationDate}
            onChange={onChange('GraduationDate')}
            errors={errors?.fieldErrors?.GraduationDate}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <Uploader
            name="idImage"
            label="College ID *"
            onChange={onChange('idImage')}
            className="mt-2 col-span-4"
            value={value.idImage}
            type="image"
            uploadCategory="education"
            uploadType="image"
            fileTypes={['png', 'jpg', 'jpeg', 'gif']}
            fileSizeLimit={10}
            errors={errors?.fieldErrors?.idImage}
          />
        </div>
        <div className="flex justify-evenly">
          <Button
            text="Close"
            onClick={close}
            color={ButtonColors.plainWithBorder}
          />
          <Button
            text="Convert"
            onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </form>
    </Modal>
  );
};

export default UserConvertModal;
