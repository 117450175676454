import Decimal from 'decimal.js';

Decimal.set({
  rounding: 7,
});

export const createDecimal = (value: number | string | Decimal): Decimal => {
  const valueToUse =
    typeof value === 'string' ? value.replace(/,/g, '') : value;
  if (value === null) value = 0; // BWM: Handle null values.
  return new Decimal(valueToUse || 0);
};
