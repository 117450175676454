import { OpenClosedStates } from '@chiroup/components';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import userService from '../../../services/user.service';
import dayjs from 'dayjs';
import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import InfoItem from '../../common/info/InfoItem';
import MoreButton from '../../common/MoreButton';
import SectionContainer from '../../layout/SectionContainer';
import SectionHeader from '../../layout/SectionHeader';
import UserConvertModal from './UserConvertModal';
import UserDeleteModal from './UserDeleteModal';
import UserGeneralEditPanel from './UserGeneralEditPanel';
import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import { formatPhone } from '@chiroup/core/functions/format';
import { User } from '@chiroup/core/types/User.type';
import { ErrorResponse } from '@chiroup/core/types/ErrorResponse.type';

type Props = {
  user: Partial<User>;
  update: UseMutateFunction<
    Partial<User>,
    ErrorResponse,
    Partial<User>,
    unknown
  >;
  del: () => Promise<void>;
  isUpdating: boolean;
  refetch: () => void;
};

const UserGeneral: React.FC<Props> = ({
  user,
  update,
  isUpdating,
  del,
  refetch,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { createToast } = useContext(ToastContext);
  const [deleteModalState, setDeleteModalState] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [editSlideOverState, setEditSlideOverState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);
  const [convertModal, setConvertModal] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const editOpen = queryParams.open?.includes('edit');
    setEditSlideOverState(
      editOpen ? OpenClosedStates.Open : OpenClosedStates.Closed,
    );
  }, [location.search]);

  const updateSlideOverState = (val: OpenClosedStates) => {
    navigate(`/users/${user.ID}`);
  };

  const resetPassword = async () => {
    try {
      await userService.resetPassword(user.email, user.fname);
    } catch (err) {
      console.error(err);
      createToast({
        title: 'Error!',
        description: <>Failed to reset user password!</>,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
    createToast({
      title: 'Success!',
      description: <>Successfully reset user password!</>,
      type: ToastTypes.Success,
      duration: 5000,
    });
  };

  return (
    <>
      <SectionContainer className="sm:shadow border border-gray-300 dark:border-darkGray-800">
        <SectionHeader
          title="General"
          subtitle="General information about the user."
          rightSide={
            <div className="flex flex-row items-center space-x-2.5">
              {/* <a
                href={`https://app.chiroup.com?impersonate=${user.email}`}
                target="_blank"
                rel="noreferrer"
                className="hidden sm:block"
              >
                <Button
                  text="Login as user"
                  icon={
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  }
                  color={ButtonColors.plainWithBorder}
                />
              </a> */}
              <MoreButton
                options={[
                  {
                    onClick: resetPassword,
                    icon: (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    ),
                    text: 'Reset password',
                    className: 'flex gap-2',
                  },
                  ...(!user.schoolName
                    ? [
                        {
                          onClick: () => setConvertModal(OpenClosedStates.Open),
                          icon: (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path d="M12 14l9-5-9-5-9 5 9 5z" />
                              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                              />
                            </svg>
                          ),
                          text: 'Convert to education',
                          className: 'flex gap-2',
                        },
                      ]
                    : []),
                ]}
              />
              <Link to="?open=edit" className="hidden sm:block">
                <Button
                  text="Edit"
                  icon={
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  }
                  color={ButtonColors.plainWithBorder}
                />
              </Link>
            </div>
          }
        />
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <InfoItem label="Email" value={user.email} />
            <InfoItem label="Phone" value={formatPhone(user.phone)} />
            {user.hasAcceptedEHRTerms && (
              <InfoItem
                label="Accepted EHR terms of use"
                value={ChiroUpDayJsCommon.getMmDdYyyy(user.hasAcceptedEHRTerms)}
              />
            )}
            <InfoItem
              label="Created"
              value={dayjs(user.createDate).format('MM/DD/YYYY')}
            />
            {user.lastLogin ? (
              <InfoItem
                label="Last login"
                value={dayjs(user.lastLogin).format('MM/DD/YYYY')}
              />
            ) : null}

            <InfoItem label="Referral" value={user.referral} />
            <InfoItem label="Referral code" value={user.referralCode} />
            {user.GraduationDate ? (
              <InfoItem
                label="Graduation date"
                value={dayjs(user.GraduationDate).format('MM/YYYY')}
              />
            ) : null}

            {/* <InfoItem label="Fax" value={formatPhone(user.fax)} />
            <InfoItem
              label="Address"
              value={
                <>
                  {user.address}
                  <br />
                  {user.city}, {user.state} {user.zip}
                </>
              }
              show={!!user.address}
            /> */}
            {/* <InfoItem label="Practice" value={user.practice} />
            <InfoItem label="Specialty" value={user.speciality} /> */}
          </dl>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <InfoItem label="Notes" value={user.notes} />
          </dl>
        </div>
      </SectionContainer>
      <UserGeneralEditPanel
        editSlideOverState={editSlideOverState}
        updateSlideOverState={updateSlideOverState}
        defaultValues={user}
        update={update}
        isUpdating={isUpdating}
      />
      {user.ID && (
        <>
          <UserDeleteModal
            state={deleteModalState}
            setState={setDeleteModalState}
            del={del}
          />
          <UserConvertModal
            isOpen={convertModal === OpenClosedStates.Open}
            close={() => setConvertModal(OpenClosedStates.Closed)}
            user={user}
            refetch={refetch}
          />
        </>
      )}
    </>
  );
};

export default UserGeneral;
