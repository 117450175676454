import { OpenClosedStates } from '@chiroup/components';
import { Form } from '@chiroup/core/types/Form.type';
import { useForm } from '@chiroup/hooks';
import { DocumentIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Accordion from '../../common/Accordion';
import Button from '../../common/Button';
import SearchInput from '../../common/fields/inputs/SearchInput';
import Filters from '../../common/Filters';
import Header from '../../layout/Header';
import Tabs from '../../layout/Tabs';
import DeleteFormModal from './DeleteFormModal';
import FormEditSlideOverPanel from './FormEditSlideOverPanel';
import useForms from './hooks/useForms';

const emptyForm: Partial<Form> = {
  category: '',
  descr: '',
  file: '',
  title: '',
  type: '',
};

type Props = {
  user?: any;
};

const FormList = ({ user }: Props) => {
  const { data, onSearch, onSelectType, refetch, tabs } = useForms();
  const { type } = useParams();
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const [currentForm, setCurrentForm] = useState<Partial<Form>>();
  const [id, setId] = useState<number | null>(null);

  useEffect(() => {
    onSelectType(type || '');
  }, [type, onSelectType]);

  const { value, onChange } = useForm<{ search: string }>(
    {
      search: '',
    },
    {},
  );

  useEffect(() => {
    onSearch(value?.search || '');
  }, [value, onSearch]);

  const toggleAccordion = (section: string) => {
    setOpenAccordions((prev) =>
      prev.includes(section)
        ? prev.filter((item) => item !== section)
        : [...prev, section],
    );
  };

  const close = () => {
    refetch();
    setCurrentForm(undefined);
  };

  return (
    <>
      <Header
        title="Forms"
        rightSide={
          <Button text="Add new" onClick={() => setCurrentForm(emptyForm)} />
        }
      >
        <Filters
          main={
            <SearchInput onChange={onChange('search')} value={value.search} />
          }
        />
      </Header>
      {data?.search ? (
        <div className="p-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {data.search?.map((item: any) => (
            <div
              key={item.ID}
              className="relative rounded-lg border border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-800 px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 dark:hover:border-darkGray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
            >
              <div className="flex-shrink-0">
                <DocumentIcon className="h-10 w-10" />
              </div>
              <div className="flex-1 min-w-0">
                <span className="inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900 dark:text-darkGray-50">
                  {item.title}
                </p>
                <p className="text-sm text-gray-500 dark:text-darkGray-400 truncate">
                  {item.descr}
                </p>
                <div className="flex gap-2">
                  <a
                    href={item.file}
                    target="_blank"
                    rel="noreferrer"
                    className="focus:outline-none"
                  >
                    View
                  </a>
                  <span
                    className="cursor-pointer"
                    onClick={() => setCurrentForm(item)}
                  >
                    Edit
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Tabs tabs={tabs} />
          {type && (
            <div className="rounded-lg sm:shadow bg-white dark:bg-darkGray-800 m-6">
              {data &&
                Object.keys(data).map((cat) => {
                  return (
                    <Accordion
                      hugTop
                      key={cat}
                      title={cat}
                      onToggle={() => toggleAccordion(`${type}-${cat}`)}
                      open={openAccordions.includes(`${type}-${cat}`)}
                    >
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                        {data[cat]?.map((item: any) => (
                          <div
                            key={item.ID}
                            className="relative rounded-lg border border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-800 px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 dark:hover:border-darkGray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                          >
                            <div className="flex-shrink-0">
                              <DocumentIcon className="h-10 w-10" />
                            </div>
                            <div className="flex-1 min-w-0">
                              <span
                                // className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900 dark:text-darkGray-50">
                                {item.title}
                              </p>
                              <p className="text-sm text-gray-500 dark:text-darkGray-400 truncate">
                                {item.descr}
                              </p>
                              <div className="flex gap-2">
                                <a
                                  href={item.file}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="focus:outline-none"
                                >
                                  View
                                </a>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => setCurrentForm(item)}
                                >
                                  Edit
                                </span>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => setId(item.ID)}
                                >
                                  Delete
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Accordion>
                  );
                })}
            </div>
          )}
        </>
      )}
      <DeleteFormModal
        isOpen={!!id}
        id={id}
        close={() => setId(null)}
        refetch={refetch}
      />
      {currentForm && (
        <FormEditSlideOverPanel
          slideOverState={
            currentForm ? OpenClosedStates.Open : OpenClosedStates.Closed
          }
          updateSlideOverState={(val) =>
            val === OpenClosedStates.Closed
              ? setCurrentForm(undefined)
              : () => {}
          }
          defaultValues={currentForm}
          setCurrentForm={setCurrentForm}
          user={user}
          close={close}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default FormList;
