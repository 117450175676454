import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import marketingService from '../services/marketing.service';
import { ErrorResponse } from '@chiroup/core/types/ErrorResponse.type';
import {
  MarketingCampaignDetail,
  MarketingCampaign,
} from '@chiroup/core/types/MarketingCampaign.type';

type ErrorData = {
  title: string;
  description: string;
  link: {
    text: string;
    url: string;
  };
};

const errorData = (error: ErrorResponse): ErrorData => {
  if (error.response?.status === 401) {
    return {
      title: 'Unauthorized',
      description: 'You are not authorized to view this marketing campaign.',
      link: {
        text: 'Go back to marketing campaigns',
        url: '/practice-resources/marketing-campaigns',
      },
    };
  }
  if (error.response?.status === 404) {
    return {
      title: 'Marketing campaign not found',
      description: 'This marketing campaign could not be found.',
      link: {
        text: 'Go back to marketing campaigns',
        url: '/practice-resources/marketing campaigns',
      },
    };
  }
  return {
    title: 'An error occurred while fetching this marketing campaign.',
    description:
      'If you believe that this is an error and would like to try again, please refresh your browser.',
    link: {
      text: 'Go back to marketing campaigns',
      url: '/practice-resources/marketing campaigns',
    },
  };
};

const getMarketingCampaignQuery = (id = '') => {
  return async () => {
    return marketingService.findOne(id);
  };
};

const useMarketingCampaign = (id?: string) => {
  const [queryErrors, setQueryErrors] = useState<ErrorData>();
  const {
    data,
    isFetching,
    refetch,
    error: queryError,
    remove,
    isFetched,
  } = useQuery<MarketingCampaignDetail, ErrorResponse>(
    ['marketingCampaigns', id],
    getMarketingCampaignQuery(id),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (queryError) {
      setQueryErrors(errorData(queryError));
    }
  }, [queryError]);

  const del = async () => {
    if (!id) {
      return;
    }
    return marketingService.del(id);
  };

  const save = (val: Partial<MarketingCampaign>) => {
    return marketingService.update(val);
  };

  return {
    isFetching,
    data,
    refetch,
    queryErrors,
    remove,
    isFetched,
    del,
    save,
  };
};

export default useMarketingCampaign;
