import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Checkbox, Input } from '@chiroup/components';
import useDevtools from '../../../hooks/useDevtools';
import { PuzzlePieceIcon } from '@heroicons/react/24/outline';
import {
  TransactionDebugParams,
  TransactionDebugSources,
  TransactionDebugSourceColumns,
} from '@chiroup/core/types/Devtools.type';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';

const defaultValues: TransactionDebugParams = {
  options: {
    include: TransactionDebugSources.reduce(
      (a, source) => {
        a[source.key] = source.checked;
        return a;
      },
      {} as Record<string, boolean>,
    ) as any,
  },
};

const TransactionDebug: React.FC = () => {
  const { get, isFetching } = useDevtools('transaction-debug');
  const [toggleAll, setToggleAll] = React.useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<TransactionDebugParams>({ defaultValues });

  const onSubmit = (data: TransactionDebugParams) => {
    if (get) {
      get(data)
        .then((res) => res)
        .catch((e) => console.error(e));
    } else {
      console.error('get is not defined');
    }
  };

  return (
    <div className="p-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="bg-white border border-gray-400 inset-4 rounded-lg">
          <div className="p-4 flex flex-row justify-between">
            <h1 className="font-extrabold">Transaction Debug</h1>
            <Button
              text="Fetch"
              disabled={isFetching}
              loading={isFetching}
              type="submit"
              icon={<PuzzlePieceIcon />}
            />
          </div>
          <div className="p-4 m-4 border border-gray-400 bg-gray-50 rounded-lg text-sm">
            <p>
              There is no UI for viewing the data. This returns a gigantic JSON
              object. So, start the browser's devtools before fetching the data.
            </p>
          </div>
          <div className="p-4">
            <Controller
              name="billingKey"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Billing Key"
                  errors={
                    errors.billingKey
                      ? ({
                          message: errors.billingKey.message,
                        } as FormError)
                      : undefined
                  }
                />
              )}
            />
            <Controller
              name="invoiceId"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Invoice ID"
                  errors={
                    errors.invoiceId
                      ? ({
                          message: errors.invoiceId.message,
                        } as FormError)
                      : undefined
                  }
                />
              )}
            />
            <Controller
              name="invoiceNumber"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Invoice Number"
                  errors={
                    errors.invoiceNumber
                      ? ({
                          message: errors.invoiceNumber.message,
                        } as FormError)
                      : undefined
                  }
                />
              )}
            />
            {/* <Controller
              name="eraId"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="ERA ID"
                  className="col-span-2"
                  errors={
                    errors.eraId
                      ? ({
                          message: errors.eraId.message,
                        } as FormError)
                      : undefined
                  }
                />
              )}
            /> */}
            <div className="mt-8">
              <div className="flex justify-between flex-row">
                <h2 className="font-extrabold">Include Options</h2>
                <div className="space-x-4 flex flex-row">
                  <Button
                    onClick={() => {
                      const tf = toggleAll;
                      setToggleAll(!toggleAll);
                      const newInclude = TransactionDebugSources.reduce(
                        (a, source) => {
                          a[source.key] = tf ? false : true;
                          return a;
                        },
                        {} as Record<string, boolean>,
                      );
                      setValue('options.include', newInclude);
                    }}
                    text={`Toggle All`}
                  />
                  <Button
                    text="Reset"
                    onClick={() => {
                      setValue(
                        'options.include',
                        defaultValues?.options?.include as any,
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 flex flex-row">
                {TransactionDebugSourceColumns.map((col: any, idx) => (
                  <div key={`col-idx-${idx}`} className="w-1/4">
                    {col.map((source: any) => (
                      <div key={source.key} className="p-2">
                        <Controller
                          name={
                            `options.include.${source.key}` as keyof TransactionDebugParams['options']
                          }
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              value={field?.value}
                              onChange={field.onChange}
                              label={source.label}
                              className="col-span-2"
                            />
                          )}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default TransactionDebug;
