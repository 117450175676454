/**
 * AlertBlock.tsx
 *
 * [2024-01-07.1401 by Brian]
 *    Just a way to put an alert message in a box consistently.
 *    A bunch of sane defaults with the ability to override them
 *    selectively. Probably, the less we override, the better.
 *
 *    Usage:
 *
 *      <AlertBock message="This is the message." />
 *
 *    Will render this in a red box with default settings:
 *
 *      Warning! This is the message.
 *
 *  omitWhenEmpty: boolean
 *     If there is no message, this won't render anything. Set this
 *     to false if you want the box to render when empty.
 *
 *  closeCallback: () => void
 *     A function returning void. If provided, a close button will
 *     appear and clicking it will execute the callback. Clearing
 *     out the message will close the alert.
 */
import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import {
  STRING_STRING_HASH,
  STRING_ANY_HASH,
} from '@chiroup/core/constants/globals';
import { classNames } from '@chiroup/core/functions/classNames';

type Props = {
  level?: string;
  margin?: string;
  padding?: string;
  text?: string;
  rounded?: string;
  role?: string;
  intro?: React.ReactNode;
  message?: React.ReactNode;
  omitWhenEmpty?: boolean;
  closeCallback?: () => void;
};

const levels: STRING_STRING_HASH = {
    default: 'text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400',
    note: 'text-blue-800 bg-blue-50 dark:bg-blue-800 dark:text-blue-400',
  },
  intros: STRING_ANY_HASH = {
    none: (
      <>
        <span className="font-medium"></span>
        {`   `}
      </>
    ),
    note: (
      <>
        <span className="font-medium">Note:</span>
        {`   `}
      </>
    ),
    error: (
      <>
        <span className="font-medium">Error:</span>
        {`   `}
      </>
    ),
    caution: (
      <>
        <span className="font-medium">Caution:</span>
        {`   `}
      </>
    ),
    default: (
      <>
        <span className="font-medium">Warning!</span>
        {`   `}
      </>
    ),
  };

export const AlertBlock: React.FC<Props> = ({
  level = 'warn',
  message = null,
  margin = 'my-4',
  padding = 'p-4',
  rounded = 'rounded-lg',
  text = 'text-sm',
  role = 'alert',
  intro = intros[level] ?? intros.default,
  omitWhenEmpty = true,
  closeCallback,
}) => {
  if (omitWhenEmpty && !message) return null;

  return (
    <div
      className={classNames(
        margin,
        padding,
        text,
        rounded,
        levels[level] ?? levels.default,
        'relative',
      )}
      role={role}
    >
      {intro}
      {message}
      {closeCallback && (
        <button
          className="absolute top-1 right-1 text-red-800 dark:text-red-400"
          onClick={closeCallback}
        >
          <XCircleIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};
