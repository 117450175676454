import { useIntersectionObserver } from '@chiroup/hooks';
import React from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import Button from './Button';
import Loading from './Loading';

type Props = {
  isFetching: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      {
        data: any[];
        lastKey?: string;
        skip?: number;
      },
      unknown
    >
  >;
  hasNextPage?: boolean;
  noMoreResultsText?: string;
  className?: string;
};

const EndOfList: React.FC<Props> = ({
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage = false,
  noMoreResultsText = 'No more results',
  className = '',
}) => {
  const loadMoreButtonRef = React.useRef<HTMLDivElement>(null);

  const onIntersect = fetchNextPage || function () {};

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect,
    enabled: hasNextPage,
  });

  return (
    <div
      className={['flex justify-center py-8 text-gray-400', className].join(
        ' ',
      )}
      ref={loadMoreButtonRef}
    >
      {isFetching || isFetchingNextPage ? (
        <Loading color="text-gray-400" size={10} className="-ml-1 mr-3" />
      ) : hasNextPage ? (
        <Button
          text="Load more"
          onClick={() => fetchNextPage?.()}
          disabled={!hasNextPage || isFetchingNextPage}
        />
      ) : (
        noMoreResultsText
      )}
    </div>
  );
};

export default EndOfList;
