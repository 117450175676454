import { useDebounce } from '@chiroup/hooks';
import { useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import marketingService from '../services/marketing.service';
import { MarketingCampaign } from '@chiroup/core/types/MarketingCampaign.type';

const getQuery = () => {
  return async (context: QueryFunctionContext) => {
    const searchTerm = context.queryKey[1] as string;
    const search = searchTerm || '';
    return marketingService.list({ skip: context.pageParam, search });
  };
};

const useMarketingCampaigns = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery);
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<{ data: MarketingCampaign[]; skip: number }>(
    ['marketingCampaigns', debouncedSearch],
    getQuery(),
    {
      getNextPageParam: (lastGroup) => lastGroup?.skip || undefined,
      refetchOnWindowFocus: false,
    },
  );

  const onSearch = (params: string) => {
    setSearchQuery(params);
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    onSearch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};

export default useMarketingCampaigns;
