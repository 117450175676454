export const surveyIds = {
  allergy: 'f570cfc9-6e2d-4d11-8629-81dbab400bc6',
  oswestry: '75e365b6-ba74-4fe5-bc04-c83c7599e652',
  healthHistory: '2d1187fe-fe32-4aff-9fc8-695174e2f080',
  dash: '308ce8dc-3f20-467e-98b4-1e183d833b4d',
  surgicalHistory: 'f79a1436-b0e6-4b19-9774-f81de54373fb',
  medicationHistory: '15b532b9-ad5f-4b49-9e55-9b021e6b24dc',
  lefs: 'b54bb66e-506a-4191-ae8d-978251fcf154',
  backBournemouth: 'bda82ea2-a5b3-4145-817a-21ab464885ed',
  lifestyleHistory: '632c8555-023d-4d0e-a44b-32b096f0832a',
  familyHistory: '113cfd2a-7005-42de-bb58-b6a446cc3a63',
  ccs: '0a91c93a-c727-46d1-b997-7f21bc630f3c',
  reviewOfSystems: 'f3d2c6b0-c9a5-42ea-9a95-32c51131f001',
  neckBournemouth: 'c411eb11-d915-4e15-9a16-bdb933516257',
  ndi: 'b005e3d9-8ca4-4694-8525-7b63312b3c6c',
  reevaluation: '721e5ee1-4bc4-4754-92d9-c74576a015f5',
  interimVisitSurvey: '7d369d2a-8d0d-11ed-a1eb-0242ac120002',
  additionalComplaintSurvey: 'a91b9b3b-cde2-482e-8168-867b09ac16b4',
  headacheDisability: '72e4644e-f535-41a8-b6b9-c1479266a0be',
  abbreviatedCCS: '3a14995d-f06e-46f4-b8bb-5fbbe7a421e9',
};

export const surveyOrder = {
  oswestry: 0,
  dash: 1,
  lefs: 2,
  backBournemouth: 3,
  neckBournemouth: 4,
  ndi: 5,
  headacheDisability: 6,
  reviewOfSystems: 7,
  healthHistory: 8,
  surgicalHistory: 9,
  medicationHistory: 10,
  allergy: 11,
  familyHistory: 12,
  lifestyleHistory: 13,
};

export const surveyIdsTypes = Object.entries(surveyIds).reduce(
  (
    obj: {
      [key: string]: keyof typeof surveyIds;
    },
    [name, id],
  ) => {
    const surveyName = name as keyof typeof surveyIds;
    obj[id] = surveyName;
    return obj;
  },
  {},
);

export type SurveyIds = keyof typeof surveyIds;

export const intakeSurveys = [
  { id: surveyIds.healthHistory, title: 'Health History' },
  { id: surveyIds.surgicalHistory, title: 'Surgical History' },
  { id: surveyIds.medicationHistory, title: 'Medication History' },
  { id: surveyIds.lifestyleHistory, title: 'Lifestyle History' },
  { id: surveyIds.familyHistory, title: 'Family History' },
  { id: surveyIds.reviewOfSystems, title: 'Review of Systems' },
  { id: surveyIds.allergy, title: 'Allergy' },
  { id: surveyIds.ccs, title: 'Chief complaint survey' },
  { id: 'requestPhotoId', title: 'Photo ID' },
  { id: 'requestInsurance', title: 'Insurance card' },
  { id: 'demographics', title: 'Patient demographics' },
];

export const functionalDisabilitySurveys = [
  { id: surveyIds.backBournemouth, title: 'Back Bournemouth Questionnaire' },
  { id: surveyIds.neckBournemouth, title: 'Neck Bournemouth Questionaire' },
  { id: surveyIds.lefs, title: 'Lower Extremity Functional Scale (LEFS)' },
  { id: surveyIds.ndi, title: 'Neck Disability Index (NDI)' },
  { id: surveyIds.dash, title: 'Quick DASH' },
  { id: surveyIds.oswestry, title: 'Revised Oswestry Disability indexed' },
];

export const disabilitySurvey = [
  '75e365b6-ba74-4fe5-bc04-c83c7599e652',
  '308ce8dc-3f20-467e-98b4-1e183d833b4d',
  'b005e3d9-8ca4-4694-8525-7b63312b3c6c',
  'b54bb66e-506a-4191-ae8d-978251fcf154',
  'bda82ea2-a5b3-4145-817a-21ab464885ed',
  'c411eb11-d915-4e15-9a16-bdb933516257',
];

export const disabilitySurveyObj: { [key: string]: boolean } = {
  '75e365b6-ba74-4fe5-bc04-c83c7599e652': true,
  '308ce8dc-3f20-467e-98b4-1e183d833b4d': true,
  'b005e3d9-8ca4-4694-8525-7b63312b3c6c': true,
  'b54bb66e-506a-4191-ae8d-978251fcf154': true,
  'bda82ea2-a5b3-4145-817a-21ab464885ed': true,
  'c411eb11-d915-4e15-9a16-bdb933516257': true,
};
