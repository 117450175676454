import { useContext, useState } from 'react';
import devtoolsService from '../services/devtools.service';
import { ToastContext, ToastTypes } from '../contexts/toast.context';
import {
  TransactionDebugResponse,
  InvoicePanelResponse,
} from '@chiroup/core/types/Devtools.type';

const useDevtools = function (feature: string) {
  const [isFetching, setIsFetching] = useState(false);
  const { createToast } = useContext(ToastContext);

  if (feature === 'transaction-debug') {
    const get = async (val: any) => {
      let res: TransactionDebugResponse | null = null;
      setIsFetching(true);
      try {
        res = await devtoolsService.transactionDebug.get(val);
        createToast({
          type: ToastTypes.Success,
          title: 'Success!',
          description: 'Check the payload in the console.',
          duration: 5000,
        });
      } catch (e: any) {
        console.error(e);
        createToast({
          type: ToastTypes.Fail,
          title: 'Error!',
          description: e?.response?.data?.message || 'An error occurred.',
          duration: 5000,
        });
      } finally {
        setIsFetching(false);
      }
      return res;
    };

    return {
      get,
      isFetching,
    };
  } else if (feature === 'invoice-panel') {
    const get = async (val: any) => {
      let res: InvoicePanelResponse | null = null;
      setIsFetching(true);
      try {
        res = await devtoolsService.invoicePanel.get(val);
        createToast({
          type: ToastTypes.Success,
          title: 'Success!',
          description: 'Check the payload in the console.',
          duration: 5000,
        });
      } catch (e: any) {
        console.error(e);
        createToast({
          type: ToastTypes.Fail,
          title: 'Error!',
          description: e?.response?.data?.message || 'An error occurred.',
          duration: 5000,
        });
      } finally {
        setIsFetching(false);
      }
      return res;
    };
    const move = async (val: any) => {
      let res: InvoicePanelResponse | null = null;
      setIsFetching(true);
      try {
        res = await devtoolsService.invoicePanel.move(val);
        createToast({
          type: ToastTypes.Success,
          title: 'Success!',
          description: 'Check the payload in the console.',
          duration: 5000,
        });
      } catch (e: any) {
        console.error(e);
        createToast({
          type: ToastTypes.Fail,
          title: 'Error!',
          description: e?.response?.data?.message || 'An error occurred.',
          duration: 5000,
        });
      } finally {
        setIsFetching(false);
      }
      return res;
    };

    return {
      get,
      isFetching,
      move,
    };
  }

  return {
    availableFeatures: ['transaction-debug', 'invoice-panel'],
  };
};

export default useDevtools;
