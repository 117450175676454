import { Icons, Input, Select } from '@chiroup/components';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { v4 } from 'uuid';
import Uploader from '../common/uploader/Uploader';
import HomePageLinks from './HomePageLinks';
import HomePageList from './HomePageList';
import HomePageSortableItem from './HomePageSortableItem';
import {
  HomePageSection,
  SubsectionTypes,
  PlanTypes,
} from '@chiroup/core/types/HomePage.type';

type Props = {
  item: HomePageSection;
  setData: React.Dispatch<React.SetStateAction<HomePageSection[]>>;
};

const HomePageSubsections: React.FC<Props> = ({ item, setData }) => {
  const addSubSection = (sectionId: string) => {
    setData((prev) => {
      prev = prev.map((item) => {
        if (item.id === sectionId) {
          item.subsections = item.subsections || [];
          item.subsections.push({
            id: v4(),
            title: 'New subsection',
            type: SubsectionTypes.video,
            icon: '',
          });
        }
        return item;
      });
      return prev;
    });
  };

  const removeSubsection = (id: string) => {
    setData((prev) => {
      prev = prev.map((v) => {
        const subIndex = v.subsections.findIndex((s) => s.id === id);
        if (subIndex !== -1) {
          v.subsections.splice(subIndex, 1);
        }
        return v;
      });
      return prev;
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <SortableContext
        id={item.id}
        items={item.subsections}
        strategy={verticalListSortingStrategy}
      >
        {item.subsections?.map((subsection) => (
          <HomePageSortableItem
            key={subsection.id}
            id={subsection.id}
            removeItem={removeSubsection}
          >
            <div className="rounded-md bg-gray-100 p-6 relative grid grid-cols-1 sm:grid-cols-6 gap-2">
              <Input
                name="text"
                label="Title *"
                className="col-span-1 sm:col-span-2"
                value={subsection.title}
                onChange={(val) => {
                  setData((prev) => {
                    prev = prev.map((v) => {
                      const subIndex = v.subsections.findIndex(
                        (s) => s.id === subsection.id,
                      );
                      if (subIndex !== -1) {
                        v.subsections[subIndex].title = val;
                      }
                      return v;
                    });
                    return prev;
                  });
                }}
              />
              <Input
                name="text"
                label="Subtitle"
                className="col-span-1 sm:col-span-2"
                value={subsection.subtitle}
                onChange={(val) => {
                  setData((prev) => {
                    prev = prev.map((v) => {
                      const subIndex = v.subsections.findIndex(
                        (s) => s.id === subsection.id,
                      );
                      if (subIndex !== -1) {
                        v.subsections[subIndex].subtitle = val;
                      }
                      return v;
                    });
                    return prev;
                  });
                }}
              />
              <Select
                name="text"
                label="Restrict to segment(s)"
                className="col-span-1 sm:col-span-2"
                value={subsection.restrictToSegments}
                options={
                  Object.keys(PlanTypes).map((key) => ({
                    text: key,
                    value: key,
                  })) || []
                }
                onChange={(val) => {
                  setData((prev) => {
                    prev = prev.map((v) => {
                      const subIndex = v.subsections.findIndex(
                        (s) => s.id === subsection.id,
                      );
                      if (subIndex !== -1) {
                        v.subsections[subIndex].restrictToSegments = val;
                      }
                      return v;
                    });
                    return prev;
                  });
                }}
              />
              <Input
                name="text"
                label="Link"
                className="col-span-1 sm:col-span-3"
                value={subsection.link}
                onChange={(val) => {
                  setData((prev) => {
                    prev = prev.map((v) => {
                      const subIndex = v.subsections.findIndex(
                        (s) => s.id === subsection.id,
                      );
                      if (subIndex !== -1) {
                        v.subsections[subIndex].link = val;
                      }
                      return v;
                    });
                    return prev;
                  });
                }}
              />
              <Input
                name="text"
                label="Link text"
                className="col-span-1 sm:col-span-3"
                value={subsection.linkText}
                onChange={(val) => {
                  setData((prev) => {
                    prev = prev.map((v) => {
                      const subIndex = v.subsections.findIndex(
                        (s) => s.id === subsection.id,
                      );
                      if (subIndex !== -1) {
                        v.subsections[subIndex].linkText = val;
                      }
                      return v;
                    });
                    return prev;
                  });
                }}
              />
              <Select
                name="text"
                label="Icon"
                className="col-span-1 sm:col-span-3"
                options={Object.keys(Icons).map((key) => ({
                  text: key,
                  value: key,
                }))}
                value={subsection.icon}
                onChange={(val) => {
                  setData((prev) => {
                    prev = prev.map((v) => {
                      const subIndex = v.subsections.findIndex(
                        (s) => s.id === subsection.id,
                      );
                      if (subIndex !== -1) {
                        v.subsections[subIndex].icon = val;
                      }
                      return v;
                    });
                    return prev;
                  });
                }}
                limit={1}
              />
              <Select
                options={Object.keys(SubsectionTypes).map((key) => ({
                  text: key,
                  value: key,
                }))}
                className="col-span-1 sm:col-span-3"
                label="Type *"
                name="type"
                value={subsection.type}
                onChange={(val) => {
                  setData((prev) => {
                    prev = prev.map((v) => {
                      const subIndex = v.subsections.findIndex(
                        (s) => s.id === subsection.id,
                      );
                      if (subIndex !== -1) {
                        v.subsections[subIndex].type = val;
                        v.subsections[subIndex].typeData = {};
                      }
                      return v;
                    });
                    return prev;
                  });
                }}
                limit={1}
              />
              {subsection.type === SubsectionTypes.video && (
                <Input
                  name="text"
                  label="Video URL *"
                  className="col-span-1 sm:col-span-6"
                  value={subsection.typeData?.videoUrl}
                  onChange={(val) => {
                    setData((prev) => {
                      prev = prev.map((v) => {
                        const subIndex = v.subsections.findIndex(
                          (s) => s.id === subsection.id,
                        );
                        if (subIndex !== -1) {
                          v.subsections[subIndex].typeData =
                            v.subsections[subIndex].typeData || {};
                          (v.subsections[subIndex].typeData as any).videoUrl =
                            val;
                        }
                        return v;
                      });
                      return prev;
                    });
                  }}
                />
              )}
              {subsection.type === SubsectionTypes.image && (
                <>
                  <div className="col-span-1 sm:col-span-6 grid sm:grid-cols-6 gap-2">
                    <Uploader
                      name="image"
                      label="Image *"
                      onChange={(val) => {
                        setData((prev) => {
                          prev = prev.map((v) => {
                            const subIndex = v.subsections.findIndex(
                              (s) => s.id === subsection.id,
                            );
                            if (subIndex !== -1) {
                              v.subsections[subIndex].typeData =
                                v.subsections[subIndex].typeData || {};
                              (
                                v.subsections[subIndex].typeData as any
                              ).imageUrl = val;
                            }
                            return v;
                          });
                          return prev;
                        });
                      }}
                      className="col-span-2"
                      value={subsection.typeData?.imageUrl}
                      type="image"
                      uploadCategory="homePage"
                      uploadType="image"
                      fileTypes={['png', 'jpg', 'jpeg', 'gif', 'webp']}
                      fileSizeLimit={100}
                    />
                  </div>
                  <Input
                    name="text"
                    label="Image Link"
                    className="col-span-1 sm:col-span-3"
                    value={subsection.typeData?.imageLink}
                    onChange={(val) => {
                      setData((prev) => {
                        prev = prev.map((v) => {
                          const subIndex = v.subsections.findIndex(
                            (s) => s.id === subsection.id,
                          );
                          if (subIndex !== -1) {
                            v.subsections[subIndex].typeData =
                              v.subsections[subIndex].typeData || {};
                            (
                              v.subsections[subIndex].typeData as any
                            ).imageLink = val;
                          }
                          return v;
                        });
                        return prev;
                      });
                    }}
                  />
                </>
              )}
              {subsection.type === SubsectionTypes.links && (
                <HomePageLinks subsection={subsection} setData={setData} />
              )}
              {subsection.type === SubsectionTypes.list && (
                <HomePageList subsection={subsection} setData={setData} />
              )}
            </div>
          </HomePageSortableItem>
        ))}
      </SortableContext>
      <PlusCircleIcon
        className="w-10 h-10 text-primary-500 hover:text-primary-400 cursor-pointer"
        onClick={addSubSection.bind(null, item.id)}
      />
    </div>
  );
};

export default HomePageSubsections;
