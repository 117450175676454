import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import {
  TransactionDebugParams,
  TransactionDebugResponse,
  InvoicePanelParams,
  InvoicePanelResponse,
} from '@chiroup/core/types/Devtools.type';

const devtoolsService = () => {
  const transactionDebug = {
    get: async (
      body: TransactionDebugParams,
    ): Promise<TransactionDebugResponse> => {
      body.fn = 'get';
      const res = await ChiroUpAPI.post('api', `/devtools/transaction-debug`, {
        body,
      });
      return res;
    },
  };
  const invoicePanel = {
    get: async (body: InvoicePanelParams): Promise<InvoicePanelResponse> => {
      body.fn = 'get';
      if (typeof body.sInvoices === 'string') {
        body.invoices = body.sInvoices.split(/\s+/);
        delete body.sInvoices;
      }
      const res = await ChiroUpAPI.post('api', `/devtools/invoice-panel`, {
        body,
      });
      return res;
    },
    move: async (body: InvoicePanelParams): Promise<InvoicePanelResponse> => {
      body.fn = 'move';
      if (typeof body.sInvoices === 'string') {
        body.invoices = body.sInvoices.split(/\s+/);
        delete body.sInvoices;
      }
      const res = await ChiroUpAPI.post('api', `/devtools/invoice-panel`, {
        body,
      });
      return res;
    },
  };

  return {
    transactionDebug,
    invoicePanel,
  };
};

export default devtoolsService();
