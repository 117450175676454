import { Checkbox, Input } from '@chiroup/components';
import { useRef } from 'react';
import Button from '../common/Button';
import { TiptapEditor } from '../tiptap/TiptapEditor';
import { FormFieldErrors } from '@chiroup/core/types/ErrorResponse.type';

type Props = {
  value: Partial<any>;
  onChange: (val: keyof any) => any;
  patchValue: (values: Partial<any>) => void;
  fieldErrors?: FormFieldErrors;
};

const ResearchEditPanelFormElements = ({
  value,
  onChange,
  patchValue,
}: Props) => {
  const van6Ref = useRef<any>(null);
  const quoteRef = useRef<any>(null);
  const feedQuoteRef = useRef<any>(null);

  const appendVan6 = () => {
    const link = `<a id="van6" href="${value.link}" target="_blank">Link</a>`;

    const editor = van6Ref.current;
    if (!editor) return;
    const currentValue = editor?.getContent();

    const newValue = currentValue.replace(/<\/p>$/, ` ${link}</p>`).trim();
    editor?.setContent(newValue);
    onChange('van6')(newValue);

    // This doesn't work because the editor is losing the id attribute...allowing that should fix this, but going to wait until I have to...

    // const oldLinkBegIndex = currentValue.indexOf('<a id="van6"');
    // let newVan6;
    // if (oldLinkBegIndex > 0) {
    //   newVan6 = currentValue.slice(0, oldLinkBegIndex - 3);
    // } else {
    //   newVan6 = currentValue;
    // }
    // editor?.setContent(newVan6.replace(/<\/p>$/, ` ${link}</p>`).trim());
  };

  const generateContent = () => {
    const van6Value = van6Ref.current?.getContent();
    const quoteValue = quoteRef.current?.getContent();

    let content = '';
    if (quoteValue && van6Value) {
      const appendVan6 = van6Value.trim();
      content = `${quoteValue.trim()}<br />${appendVan6.trim()}`;
    } else if (quoteValue) {
      content = quoteValue.trim();
    } else if (van6Value) {
      content = van6Value.trim();
    }
    feedQuoteRef.current?.setContent(content);
    onChange('feedQuote')(content);
    // patchValue({
    //   feedQuote: content,
    // });
  };

  return (
    <>
      <Input
        type="text"
        value={value?.link}
        label="Link"
        name="link"
        onChange={onChange('link')}
        className="col-span-4"
        clickIcon
        iconRight
        icon={
          <span
            className="text-sm cursor-pointer text-primary-600 z-20"
            onClick={appendVan6}
          >
            Append to VAN6
          </span>
        }
      />
      <TiptapEditor
        label="VAN 6"
        value={value?.van6}
        onChange={onChange('van6')}
        containerClassName="col-span-4 wysiwyg-format"
        ref={van6Ref}
      />
      <TiptapEditor
        label="Quote"
        value={value?.quote}
        onChange={onChange('quote')}
        containerClassName="col-span-4 wysiwyg-format"
        ref={quoteRef}
      />
      <div className="flex flex-col w-full col-span-4">
        <label className="block text-sm font-medium leading-5 text-gray-900  dark:text-darkGray-200 sm:mt-px sm:pt-2">
          Newsfeed
        </label>
        <div className="flex gap-2 mt-2">
          <Checkbox
            label="Pending"
            value={value?.newsFeedExisting}
            onChange={onChange('newsFeedExisting')}
            disabled={
              value?.newsFeedComplete ||
              value?.newsFeedPublished ||
              value?.nfPriority
            }
          />
          <Checkbox
            label="Complete"
            value={value?.newsFeedComplete}
            onChange={onChange('newsFeedComplete')}
            disabled={!value?.newsFeedExisting || value?.newsFeedPublished}
          />
          <Checkbox
            label="Published"
            value={value?.newsFeedPublished}
            onChange={onChange('newsFeedPublished')}
            disabled={true}
          />
          <Checkbox
            label="Priority"
            value={value?.nfPriority}
            onChange={onChange('nfPriority')}
            disabled={!value.newsFeedExisting}
          />
        </div>
        <Input
          name="feedTitle"
          label="Title"
          value={value?.feedTitle}
          onChange={onChange('feedTitle')}
        />
        <TiptapEditor
          value={value?.feedQuote}
          onChange={onChange('feedQuote')}
          containerClassName="col-span-4 wysiwyg-format pt-2"
          ref={feedQuoteRef}
        />
        <div>
          <Button
            className="mt-5"
            text="Content"
            onClick={generateContent}
            disabled={!value.quote || !value.van6}
          />
        </div>

        <hr className="mt-6" />
      </div>
      <div className="flex flex-col col-start-1 col-end-3">
        <div className="mt-2 flex justify-between gap-2">
          <Checkbox
            label="CSM"
            value={value?.csmPublished}
            onChange={onChange('csmPublished')}
            // disabled={value?.csmPublished}
          />
        </div>
        <div className="mt-2 flex justify-between gap-2">
          <Checkbox
            label="Clinical Asset"
            value={value?.fbExisting}
            onChange={onChange('fbExisting')}
            // disabled={value?.fbComplete}
          />
          <Checkbox
            label="Complete"
            value={value?.fbComplete}
            onChange={onChange('fbComplete')}
            // disabled={!value?.fbExisting}
          />
        </div>
        <div className="mt-2 flex justify-between gap-2">
          <Checkbox
            label="Protocol"
            value={value?.protocolExisting}
            onChange={onChange('protocolExisting')}
            // disabled={value?.protocolPublished}
          />
          <Checkbox
            label="Published"
            value={value?.protocolPublished}
            onChange={onChange('protocolPublished')}
            // disabled={!value?.protocolExisting}
          />
        </div>
        <div className="mt-2 flex justify-between gap-2">
          <Checkbox
            label="Blog topic"
            value={value?.blogExisting}
            onChange={onChange('blogExisting')}
            // disabled={value?.blogPublished}
          />
          <Checkbox
            label="Published"
            value={value?.blogPublished}
            onChange={onChange('blogPublished')}
            // disabled={!value?.blogExisting}
          />
        </div>
        <div className="mt-2 flex justify-between gap-2">
          <Checkbox
            label="M.D. newsletter"
            value={value?.mdNewsletterExisting}
            onChange={onChange('mdNewsletterExisting')}
            // disabled={value?.mdNewsletterPublished}
          />
          <Checkbox
            label="Published"
            value={value?.mdNewsletterPublished}
            onChange={onChange('mdNewsletterPublished')}
            // disabled={!value?.mdNewsletterExisting}
          />
        </div>
        <div className="mt-2 flex justify-between gap-2">
          <Checkbox
            label="Attorney newsletter"
            value={value?.legalNewsletterExisting}
            onChange={onChange('legalNewsletterExisting')}
            // disabled={value?.legalNewsletterPublished}
          />
          <Checkbox
            label="Published"
            value={value?.legalNewsletterPublished}
            onChange={onChange('legalNewsletterPublished')}
            // disabled={!value?.legalNewsletterExisting}
          />
        </div>
      </div>
    </>
  );
};

export default ResearchEditPanelFormElements;
