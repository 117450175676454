import { useEffect, useState } from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import userService from '../services/user.service';
import { User } from '@chiroup/core/types/User.type';
import {
  ErrorResponse,
  FormFieldErrors,
} from '@chiroup/core/types/ErrorResponse.type';

const getUserQuery = (ID = '') => {
  return async (context: QueryFunctionContext) => {
    return userService.findOne(ID);
  };
};

const updateUser = () => {
  return async (val: Partial<User>) => {
    if (val.ID) {
      return userService.update(val);
    }
    return userService.create(val);
  };
};

const useUser = (ID?: string) => {
  const queryClient = useQueryClient();
  const [serverErrors, setServerErrors] = useState<FormFieldErrors | null>();
  const { data, isFetching, refetch, remove, isFetched } = useQuery<
    User,
    ErrorResponse
  >(['users', ID], getUserQuery(ID), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    mutate: update,
    error,
    isSuccess,
    isLoading: isUpdating,
  } = useMutation<Partial<User>, ErrorResponse, Partial<User>>(updateUser(), {
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(['users', ID], (old) => result);
    },
  });

  useEffect(() => {
    setServerErrors(error?.response?.data?.fieldErrors || null);
  }, [error]);

  const del = async () => {
    if (!ID) {
      return;
    }
    return await userService.del(ID);
  };

  return {
    isFetching,
    data,
    update,
    serverErrors,
    isSuccess,
    refetch,
    remove,
    isFetched,
    isUpdating,
    del,
  };
};

export default useUser;
