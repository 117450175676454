export enum FeatureFlags {
  ehr = 'ehr',
  ehrNotes = 'ehrNotes',
  ehrDashboard = 'ehrDashboard',
  priorExercises = 'priorExercises',
  posNegEvaluations = 'posNegEvaluations',
  patientNotes = 'patientNotes',
  marketingAddOns = 'marketingAddOns',
  scheduling = 'scheduling',
  macros = 'macros',
  hidePatientQuickButtons = 'hidePatientQuickButtons',
  clinicDatabases = 'clinicDatabases',
  billingInsurance = 'billingInsurance',
  ICD11 = 'ICD11',
  reporting = 'reporting',
  packages = 'packages',
  superAppointments = 'superAppointments',
  workFlows = 'workFlows',
}

export enum FlagGroups {
  everyone = 'everyone',
  beta = 'beta',
  alpha = 'alpha',
  internal = 'internal',
}

export const flagGroupsArr = [
  {
    label: 'Internal',
    value: 'internal',
  },
  {
    label: 'Alpha',
    value: 'alpha',
  },
  {
    label: 'Beta',
    value: 'beta',
  },
  {
    label: 'Everyone',
    value: 'everyone',
  },
];
