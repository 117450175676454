import { OpenClosedStates } from '@chiroup/components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import userService from '../../../services/user.service';
import Button, { ButtonColors } from '../../common/Button';
import ConfirmModal from '../../common/ConfirmModal';
import AddUserModal from './AddUserModal';
import ChangeRoleModal from './ChangeRoleModal';
import { ListClinic } from '@chiroup/core/types/Clinic.type';
import { User, USER_ROLE_DISPLAY } from '@chiroup/core/types/User.type';

type Props = {
  users: User[];
  clinicID?: number;
  refetch: () => void;
  clinic: Partial<ListClinic>;
};

const ClinicUsers = ({ users, clinicID, refetch, clinic }: Props) => {
  const [removeModalState, setRemoveModalState] = useState(
    OpenClosedStates.Closed,
  );
  const [addUserModalState, setAddUserModalState] = useState(
    OpenClosedStates.Closed,
  );
  const [isRemoving, setIsRemoving] = useState(false);
  const [selectedUserToRemove, setSelectedUserToRemove] =
    useState<Partial<User>>();
  const [changeRoleModalState, setChangeRoleModalState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);
  const removeUser = async (userToRemove: Partial<User>) => {
    setSelectedUserToRemove(userToRemove);
    setRemoveModalState(OpenClosedStates.Open);
  };
  const [userToUpdate, setUserToUpdate] = useState<User>();

  const leaveClinicConfirm = async () => {
    if (!selectedUserToRemove?.ID || !clinicID) {
      return;
    }
    setIsRemoving(true);
    try {
      await userService.removeFromClinic(selectedUserToRemove.ID, clinicID);
      setIsRemoving(false);
      setRemoveModalState(OpenClosedStates.Closed);
      setSelectedUserToRemove(undefined);
      refetch();
    } catch (err) {
      setIsRemoving(false);
    }
  };

  return (
    <div className="flex flex-col mt-6 mb-4 mx-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-300 sm:rounded-lg">
            <div className="bg-white w-full border-b border-gray-300">
              <div className="px-6 py-4 bg-white flex items-center justify-between">
                <div className="ml-4">Users</div>
                <div>
                  <Button
                    text="Add user"
                    onClick={() => setAddUserModalState(OpenClosedStates.Open)}
                    color={ButtonColors.plainWithBorder}
                  />
                </div>
              </div>
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="bg-white divide-y divide-gray-300">
                {users?.map((user) => (
                  <tr key={user.ID}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <Link to={`/users/${user.ID}`}>
                            <div className="text-sm font-medium text-gray-900">
                              {`${user.title || ''} ${user.fname} ${
                                user.lname
                              }`}
                            </div>
                          </Link>
                          <div className="text-sm text-gray-500">
                            {user.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="text-sm text-gray-900 dark:text-darkGray-50 flex flex-row flex-wrap gap-1">
                        {user.role?.map((role) => (
                          <span
                            key={role}
                            className="px-2 inline-flex text-xs rounded-full bg-gray-100 text-gray-700"
                          >
                            {USER_ROLE_DISPLAY[role]}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="text-primary-600 hover:text-primary-500 cursor-pointer"
                        onClick={() => {
                          setUserToUpdate(user);
                          setChangeRoleModalState(OpenClosedStates.Open);
                        }}
                      >
                        Change role
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="text-primary-600 hover:text-primary-500 cursor-pointer"
                        onClick={removeUser.bind(null, user)}
                      >
                        Remove
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ChangeRoleModal
        isOpen={changeRoleModalState}
        setIsOpen={setChangeRoleModalState}
        user={userToUpdate}
        clinicID={clinicID}
        isEHR={clinic?.ehr}
      />
      <ConfirmModal
        state={removeModalState}
        confirm={leaveClinicConfirm}
        close={() => {
          setSelectedUserToRemove(undefined);
          setRemoveModalState(OpenClosedStates.Closed);
        }}
        title="Remove from clinic"
        description={`Are you sure you want to remove ${
          selectedUserToRemove?.name || 'this user'
        }?`}
        loading={isRemoving}
        confirmText="Remove"
      />
      <AddUserModal
        isOpen={addUserModalState}
        setIsOpen={setAddUserModalState}
        clinicID={clinicID}
        refetch={refetch}
        isEHR={clinic?.ehr}
      />
    </div>
  );
};

export default ClinicUsers;
