import {
  AssociateReferenceCodes,
  Input,
  OpenClosedStates,
} from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import React, { useContext, useState } from 'react';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import Button, { ButtonColors } from '../../common/Button';
import ConfirmModal from '../../common/ConfirmModal';
import SlideOver from '../../common/SlideOver';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import { TiptapEditor } from '../../tiptap/TiptapEditor';
import { CodeSets } from '@chiroup/core/types/BillingCode.type';
import { Test } from '@chiroup/core/types/Test.type';

type Props = {
  editSlideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  defaultValues: Partial<Test>;
  save: (val: Partial<Test>) => Promise<Test>;
  // tags: SelectOption[];
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<Test, unknown>>;
  del?: () => void;
  isDeleting?: boolean;
};

const validation = {
  name: {
    required: {
      message: 'Name is required.',
    },
  },
};

const TestDetailEditPanel: React.FC<Props> = ({
  editSlideOverState,
  updateSlideOverState,
  defaultValues,
  save,
  // tags,
  del,
  isDeleting,
  refetch,
}) => {
  const { createToast } = useContext(ToastContext);
  const [confirmOpen, setConfirmOpen] = useState(OpenClosedStates.Closed);
  const {
    value,
    registerSubmit,
    isDirty,
    errors,
    onChange,
    isSubmitting,
    patchValue,
  } = useForm<Test>(defaultValues, validation);

  const onSubmit = async (test: Partial<Test>) => {
    return save(test);
  };

  const confirmDelete = () => {
    setConfirmOpen(OpenClosedStates.Open);
  };

  return (
    <SlideOver
      title="Edit test"
      slideOverState={editSlideOverState}
      updateSlideOverState={updateSlideOverState}
      buttons={
        <>
          <Button
            text="Close"
            onClick={() => updateSlideOverState(OpenClosedStates.Closed)}
            color={ButtonColors.plain}
          />

          <Button
            text={defaultValues.parentID ? 'Revert' : 'Delete'}
            onClick={confirmDelete}
            color={ButtonColors.plain}
          />

          <Button
            text="Save"
            disabled={!isDirty}
            loading={isSubmitting}
            onClick={registerSubmit(onSubmit.bind(null), {
              onSuccess: () => {
                createToast({
                  title: 'Success!',
                  description: <>Successfully saved!</>,
                  type: ToastTypes.Success,
                  duration: 5000,
                });
                refetch?.();
              },
            })}
          />
        </>
      }
    >
      <form>
        <div className="py-6 space-y-6 sm:space-y-0 sm:py-0">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            <Input
              name="name"
              className="col-span-4"
              label="Name *"
              value={value.name}
              onChange={onChange('name')}
              errors={errors?.fieldErrors?.name}
            />
            {/*<Select*/}
            {/*  name="tags"*/}
            {/*  label="Tags"*/}
            {/*  className="col-span-2"*/}
            {/*  options={(value.tags || [])?.reduce((arr, tag) => {*/}
            {/*    if (arr.some((item) => item.value === tag)) {*/}
            {/*      return arr;*/}
            {/*    }*/}
            {/*    return arr.concat({*/}
            {/*      value: tag,*/}
            {/*      text: tag,*/}
            {/*    });*/}
            {/*  }, tags || [])}*/}
            {/*  onChange={onChange('tags')}*/}
            {/*  value={value.tags}*/}
            {/*  errors={errors?.fieldErrors?.tags}*/}
            {/*/>*/}
            <TiptapEditor
              containerClassName="col-span-4"
              name="descr"
              label="Description *"
              onChange={onChange('descr')}
              value={value.descr}
              errors={errors?.fieldErrors?.descr}
            />
            <AssociateReferenceCodes
              value={value}
              patchValue={patchValue}
              // className="col-span-4 my-8 bg-gray-100 rounded-md p-0"
              addClassName="col-span-4 mt-4"
              codeSet={[CodeSets.CPT, CodeSets.SERVICE]}
              endpoint="/codes"
              noCodesMessage="No codes are associated with this test."
              topGutter={
                <div className="col-span-4">
                  <br />
                  <hr />
                  <br />
                </div>
              }
              local={true}
            />
          </div>
        </div>
        {/* <pre>{JSON.stringify(value?.referenceCodes || [], null, 2)}</pre> */}
      </form>
      {del && (
        <ConfirmModal
          state={confirmOpen}
          confirm={del}
          close={() => setConfirmOpen(OpenClosedStates.Closed)}
          loading={isDeleting}
          confirmText={defaultValues.parentID ? 'Revert' : 'Delete'}
          description={`Are you sure you want to ${
            defaultValues.parentID ? 'revert' : 'delete'
          } this test?`}
        />
      )}
    </SlideOver>
  );
};

export default TestDetailEditPanel;
