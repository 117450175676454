import { OpenClosedStates } from '@chiroup/components';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../layout/Header';
import useTests from '../../hooks/useTests';
import Button, { ButtonColors } from '../common/Button';
import SearchInput from '../common/fields/inputs/SearchInput';
import Filters from '../common/Filters';
import StandardList from '../common/list/StandardList';
import TestDetailEditPanel from './detail/TestDetailEditPanel';
import testService from '../../services/test.service';
import { CustomizationListItem } from '../practice-resources/forms-library/CustomizationListItem.type';
import { Test } from '@chiroup/core/types/Test.type';
import { ValueOf } from '@chiroup/core/types/ValueOf.type';

type Props = {
  // tags?: SelectOption[];
  value: Partial<Test>;
  onChange: (key: keyof Test) => (val: ValueOf<Test>) => void;
};

const TestList: React.FC<Props> = ({
  // tags = [],
  value,
  onChange,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { data, isFetching } = useTests();
  const [dataWithSmallDescr, setDataWithSmallDescr] = useState<Partial<Test>[]>(
    [],
  );
  const [page, setPage] = useState<
    { data: CustomizationListItem[]; skip?: number | undefined }[] | undefined
  >();
  const [editSlideOverState, setEditSlideOverState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  useEffect(() => {
    if (data?.data?.length) {
      const dataWithSmallDescr = data?.data?.map((item: Partial<Test>) => {
        item.descr = item?.descr?.replace(
          /<[^>]*>|&lt;[^&gt;]*&gt;|amp;/gi,
          ' ',
        );
        if (item?.descr?.length || 0 > 300) {
          item.descr = item?.descr?.substr(0, 297) + '...';
        }
        return item;
      });
      setDataWithSmallDescr(dataWithSmallDescr);
    }
  }, [data]);

  useEffect(() => {
    if (dataWithSmallDescr?.length) {
      const res = dataWithSmallDescr?.filter((adl: Partial<Test>) => {
        return Object.entries(value).every(([searchProp, searchVal]) => {
          if (typeof searchVal === 'string' && searchVal) {
            return (adl[searchProp as keyof Test] as string)
              ?.toLowerCase()
              .includes(searchVal.toLowerCase());
          } else if (Array.isArray(searchVal) && searchVal.length) {
            return (searchVal as unknown[]).some(
              (val: unknown) =>
                (adl[searchProp as keyof Partial<Test>] as string)?.includes(
                  val as string,
                ),
            );
          } else if (typeof searchVal === 'boolean') {
            return (adl[searchProp as keyof Test] as unknown) === searchVal;
          }
          return true;
        });
      });
      setPage([{ data: res as CustomizationListItem[] }]);
    }
  }, [dataWithSmallDescr, value]);

  useEffect(() => {
    const queryParams = qs.parse(search);
    const editOpen = queryParams.open?.includes('add');
    setEditSlideOverState(
      editOpen ? OpenClosedStates.Open : OpenClosedStates.Closed,
    );
  }, [search]);

  const updateSlideOverState = () => {
    navigate(`/tests`);
  };

  const save = async (val: Partial<Test>) => {
    const res = await testService.add(val);
    navigate(`/tests/${res.ID}`);
    return res;
  };

  return (
    <div id="test-list">
      <Header
        title="Tests"
        rightSide={
          <Link to="/tests?open=add">
            <Button
              text="Add"
              color={ButtonColors.plainWithBorder}
              className="cursor-pointer"
              icon={
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              }
            />
          </Link>
        }
      >
        <Filters
          main={
            <>
              <SearchInput
                onChange={onChange('name')}
                value={value.name}
                label="name"
              />
              {/*<RegionSelect*/}
              {/*  onChange={onChange('regions')}*/}
              {/*  value={value.regions}*/}
              {/*  limit={1}*/}
              {/*/>*/}
            </>
          }
          additional={
            <SearchInput
              onChange={onChange('descr')}
              value={value.descr}
              label="Description"
            />
          }
        />
      </Header>
      <StandardList
        data={page}
        isFetching={isFetching}
        pathPrefix="/tests"
        clientSide
        suppressImage={true}
      />

      {editSlideOverState === OpenClosedStates.Open && (
        <TestDetailEditPanel
          editSlideOverState={editSlideOverState}
          updateSlideOverState={updateSlideOverState}
          defaultValues={{}}
          save={save}
          // tags={tags}
        />
      )}
    </div>
  );
};

export default TestList;
