import { useEffect, useState } from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import clinicService from '../services/clinic.service';
import { ListClinic } from '@chiroup/core/types/Clinic.type';
import {
  ErrorResponse,
  FormFieldErrors,
} from '@chiroup/core/types/ErrorResponse.type';

const getClinicQuery = (id?: string) => {
  return async (context: QueryFunctionContext) => {
    return clinicService.findOne(id);
  };
};

const updateClinic = () => {
  return async (val: Partial<ListClinic>) => {
    if (val.ID) {
      return clinicService.update(val);
    }
    return clinicService.create(val);
  };
};

const useClinic = (id?: string) => {
  const queryClient = useQueryClient();
  const [data, setData] = useState<Partial<ListClinic>>();
  const [serverErrors, setServerErrors] = useState<FormFieldErrors | null>();
  const {
    data: queryData,
    isFetching,
    refetch,
    remove,
    isFetched,
  } = useQuery<ListClinic, ErrorResponse>(['clinics', id], getClinicQuery(id), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    mutate: update,
    data: saveData,
    error,
    isSuccess,
    isLoading: isUpdating,
  } = useMutation<Partial<ListClinic>, ErrorResponse, Partial<ListClinic>>(
    updateClinic(),
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(['clinics', id], (old) => result);
      },
    },
  );

  useEffect(() => {
    setServerErrors(error?.response?.data?.fieldErrors || null);
  }, [error]);

  const del = async () => {
    if (!id) {
      return;
    }
    return await clinicService.del(id);
  };

  useEffect(() => {
    setData(saveData || queryData);
  }, [saveData, queryData]);

  return {
    isFetching,
    data,
    update,
    serverErrors,
    isSuccess,
    refetch,
    remove,
    isFetched,
    isUpdating,
    del,
  };
};

export default useClinic;
