import { formatPhone } from '@chiroup/core/functions/format';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import {
  CheckCircleIcon,
  ChevronRightIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

const displayAddress = ({
  name,
  address1,
  address2,
  city,
  state,
  zip,
  country,
}: ClinicLocation) => {
  const addressItems = [
    name,
    address1,
    address2,
    city,
    state,
    zip,
    country,
  ].filter((item) => item);
  return addressItems.join(', ');
};

type Props = {
  location: ClinicLocation;
};

const ClinicLocationComponent: React.FC<Props> = ({ location }) => {
  return (
    <div className="block hover:bg-gray-50 cursor-pointer">
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="text-sm font-medium text-primary-600 truncate flex flex-row gap-2">
                <span>{displayAddress(location)}</span>
                {!!location.primary && (
                  <span className="inline-flex items-center rounded-full bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-primary-800">
                    Primary
                  </span>
                )}
              </p>
              {!!location.phone && (
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <PhoneIcon
                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="truncate">
                    {formatPhone(location.phone)}
                  </span>
                </p>
              )}
            </div>
            <div className="hidden md:block">
              <div>
                {location.active ? (
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <CheckCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary-600"
                      aria-hidden="true"
                    />
                    Active
                  </p>
                ) : (
                  <p className="mt-2 flex items-center text-sm text-gray-400">
                    <CheckCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300"
                      aria-hidden="true"
                    />
                    Inactive
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
};

export default ClinicLocationComponent;
