import { OpenClosedStates } from '@chiroup/components';
import { isEmpty } from '@chiroup/core/functions/isEmpty';
import { UserClinic } from '@chiroup/core/types/User.type';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import userService from '../../../services/user.service';
import Button, { ButtonColors } from '../../common/Button';
import ConfirmModal from '../../common/ConfirmModal';
import AddClinicModal from './AddClinicModal';

type Props = {
  clinics?: Partial<UserClinic>[];
  userID?: string;
  refetch: () => void;
};

const UserClinics = ({ clinics, refetch, userID }: Props) => {
  const [leaveModalState, setLeaveModalState] = useState(
    OpenClosedStates.Closed,
  );
  const [isLeaving, setIsLeaving] = useState(false);
  const [selectedClinicToLeave, setSelectedClinicToLeave] =
    useState<Partial<UserClinic>>();
  const [addClinicModalState, setAddClinicModalState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  const leaveClinic = async (clinicToLeave: Partial<UserClinic>) => {
    setSelectedClinicToLeave(clinicToLeave);
    setLeaveModalState(OpenClosedStates.Open);
  };

  const leaveClinicConfirm = async () => {
    if (isEmpty(selectedClinicToLeave?.ID) || !userID) {
      return;
    }

    const clinicID = selectedClinicToLeave?.ID as number;
    setIsLeaving(true);
    try {
      await userService.removeFromClinic(userID, clinicID);
      setIsLeaving(false);
      setLeaveModalState(OpenClosedStates.Closed);
      setSelectedClinicToLeave(undefined);
      refetch();
    } catch (err) {
      setIsLeaving(false);
    }
  };

  return (
    <div className="flex flex-col mt-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-300 sm:rounded-lg">
            <div className="bg-white w-full border-b border-gray-300">
              <div className="px-6 py-4 bg-white flex items-center justify-between">
                <div className="ml-4">Clinics</div>
                <div>
                  <Button
                    text="Add user to a clinic"
                    onClick={() =>
                      setAddClinicModalState(OpenClosedStates.Open)
                    }
                    color={ButtonColors.plainWithBorder}
                  />
                </div>
              </div>
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="bg-white divide-y divide-gray-300">
                {clinics?.map((clinic) => (
                  <tr key={clinic.ID}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={clinic.logo}
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <Link to={`/clinics/${clinic.ID}`}>
                            <div className="text-sm font-medium text-gray-900">
                              {clinic.name}
                            </div>
                          </Link>
                          <div className="text-sm text-gray-500">
                            {clinic.location}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {clinic.role}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="text-primary-600 hover:text-primary-500 cursor-pointer"
                        onClick={leaveClinic.bind(null, clinic)}
                      >
                        Remove
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmModal
        state={leaveModalState}
        confirm={leaveClinicConfirm}
        close={() => {
          setSelectedClinicToLeave(undefined);
          setLeaveModalState(OpenClosedStates.Closed);
        }}
        title="Leave clinic"
        description={`Are you sure you want to leave ${
          selectedClinicToLeave?.name || 'this clinic'
        }?`}
        loading={isLeaving}
        confirmText="Leave"
      />
      <AddClinicModal
        isOpen={addClinicModalState}
        setIsOpen={setAddClinicModalState}
        refetch={refetch}
        userID={userID}
      />
    </div>
    // </SectionContainer>
  );
};

export default UserClinics;
