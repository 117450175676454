export enum PlanTypes {
  'ehr' = 'ehr',
  'plus' = 'plus',
  'premium' = 'premium',
  'basic' = 'basic',
  'trial' = 'trial',
  'education' = 'education',
}

export enum SubsectionTypes {
  video = 'video',
  image = 'image',
  list = 'list',
  links = 'links',
  research = 'research',
}

export type HomePageLink = {
  id: string;
  title: string;
  url: string;
};

export type HomePageListItem = {
  id: string;
  title: string;
  subtitle: string;
  url: string;
};

export type HomePageSubsection = {
  id: string;
  icon: string;
  title: string;
  type: SubsectionTypes;
  restrictToSegments?: PlanTypes[];
  subtitle?: string;
  link?: string;
  linkText?: string;
  typeData?: {
    videoUrl?: string;
    imageUrl?: string;
    imageLink?: string;
    links?: HomePageLink[];
    list?: HomePageListItem[];
  };
};

export type HomePageSection = {
  id: string;
  title: string;
  buttonText?: string;
  buttonLink?: string;
  subsections: HomePageSubsection[];
};
